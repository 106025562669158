import { combineReducers } from "redux";
import mode from "./mode/reducer";
import mnemonic from './mnemonic/reducer'
import mnemonicConfirm from './mnemonicConfirm/reducer'
import subAccount from './subAccount/reducer'

const appReducer = combineReducers({
  mode,
  mnemonic,
  mnemonicConfirm,
  subAccount
});

export default appReducer;
