import { SET_SUB_ACCOUNT, SET_CONNECTION_MODAL } from "./action";

const initialState = {
    parentAccount:"",
    isSubConnected:false,
    isConnectionModal:false
}

export function subAccountReducer(state = initialState, action) {
    switch (action.type) {
        case SET_SUB_ACCOUNT: {
            return {
                ...state,
                ...action.payload
            }
        }
        case SET_CONNECTION_MODAL:{
            return {
                ...state,
                ...action.payload
            }
        }
        default: {
            return state;
        }
    }
}

export default subAccountReducer;