import { useEffect, useState } from "react";
import { FALLBACK_PROVIDERS, getFallbackRpcUrl, getRpcUrl } from "../../config/chains";
import { ethers } from "ethers";
import { JsonRpcProvider, Web3Provider } from "@ethersproject/providers";
import { BrowserProvider } from 'ethers6';

export async function getEtherV6Provider() {
	if (!window.ethereum) {
		throw new Error('MetaMask is not installed!');
	}

	// 创建 BrowserProvider 实例
	const provider = new BrowserProvider(window.ethereum);

	try {
		// // 请求用户连接钱包并授权
		// await window.ethereum.request({ method: 'eth_requestAccounts' });

		// 获取当前选中的账户的 Signer
		return provider.getSigner();
	} catch (error) {
		throw new Error('User denied account access or other error occurred.');
	}
}

export function getProvider(library, chainId) {
  let provider;

  if (library) {
    return library.getSigner();
  }

  provider = getRpcUrl(chainId);

  return new ethers.providers.StaticJsonRpcProvider(
    provider,
    // @ts-ignore incorrect Network param types
    { chainId }
  );
}

export function getFallbackProvider(chainId) {
  if (!FALLBACK_PROVIDERS[chainId]) {
    return;
  }

  const provider = getFallbackRpcUrl(chainId);

  return new ethers.providers.StaticJsonRpcProvider(
    provider,
    // @ts-ignore incorrect Network param types
    { chainId }
  );
}

export function useJsonRpcProvider(chainId) {
  const [provider, setProvider] = useState();

  useEffect(() => {
    async function initializeProvider() {
      const rpcUrl = getRpcUrl(chainId);

      if (!rpcUrl) return;

      const provider = new ethers.providers.JsonRpcProvider(rpcUrl);

      await provider.ready;

      setProvider(provider);
    }

    initializeProvider();
  }, [chainId]);

  return { provider };
}
