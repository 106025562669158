import { ethers } from "ethers";
const { parseEther } = ethers.utils;

export default {
  networkId: 421614, // 1:主网, 3：Ropsten测试链, 128: 火币主网,256: 火币测试链 42：kovan测试
  baseUrl: "https://testgmxapi.weequan.cyou",
  testTransactionUrl: "https://dev.deriw.com/trading-prop/trade",
  deriwTransactionUrl: "https://www.deriw.com/trading-prop/trade",

  aboutFundPoolDetailUrl: "https://docs.deriw.com/liquidity/liquidity-on-v1",
  klineUrl: "https://chart.test.deriw.com/",
  duneUrl: "https://dune.com/deriw_com/deriw-analytics",
  wsUrl: "wss://testgmxapi.weequan.cyou/ws",
  explorer: "http://explorer.dev.deriw.com/",
  tx: 'http://explorer.dev.deriw.com/tx/',
  deriwExplorer: "https://explorer.deriw.com/",
  deriwTx: 'https://explorer.deriw.com/tx/',
  tokenAssetBaseUrl: "https://oss.deriw.com/tokens/",
  shareBaseUrl: "https://fh-dex.oss-ap-southeast-1.aliyuncs.com/fh-dex/client/invitation/",

  telegramgb: "https://t.me/deriwofficial",
  telegram: "https://t.me/deriwfinance",
  twitter: "https://twitter.com/DeriwFi",
  medium: "https://medium.com/@DeriwFi",
  discord: "https://discord.com/invite/deriwfinance",
  coinmarketcap: "https://coinmarketcap.com/community/profile/Deriw/",
  github: "https://github.com/deriwfi",
  docs: "https://docs.deriw.com",
  auditReport: "https://skynet.certik.com/projects/deriw-finance",

  sergentPrice: 99, // 100 usdt
  lieutenantPrice: 199, // 300 usdt
  chainName: 'Deriw Testnet',
  SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.0005"),
  INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0005"),
  netWorkIcon: "https://oss.deriw.com/chain/",
  ethRpc: "https://rpc.dev.deriw.com",

  mainChainAddr: "0x0000000000000000000000000000000000000000", // 主链币是：BSC
  // mainChainAddr: "0x21007fbfe34972C9B968a4AB1AB94AfdE9170244", // 主链币是：BSC

  address0: "0x0000000000000000000000000000000000000000",
  UnBindReferral: "0x0000000000000000000000000000000000000000000000000000000000000000", // 邀请解除绑定传的地址
  isRepeatCode: "0x0000000000000000000000000000000000000000", // 生成邀请码时校验 等于0地址 邀请码就不重复
  
  Vault: "0x6Edd10901F6ea420B8CbfaAedd1B434f7C78EF79",
  Router: "0x051d3b8390Caffdc8B579A1B674e8572363FaBb5",
  VaultUtils: "0x7A728137b5eBA7f11a217C687f5E8c360efC141b",
  VaultReader: "0xE19e766190158cAd76fDf98380dD3BB2cd78DbC1",
  Reader: "0x09e765E696eC69250b2fA05BcfA761459658449c",
  GlpManager: "0xEC34A5Ceac3A3d0b0fEB71525605Ce7158f66e4a",
  GlpRewardRouter: "0xB8dEdD1476f2d0754F3975D9a973Cc2cAC361D63",
  NATIVE_TOKEN: "0x69028A980f80f79d6EC6f455c067970619aea6F3", // (WETH)
  GLP: "0x02471426142D929E1Ada1d937136E3DdcE4039B2",
  OrderBook: "0xeF4F790755e2Cdad43E8fc6570B4BFae3BA11525",
  OrderBookReader: "0x1e1861Dbd84585878dEcC694f83A07f9EC6c3F36",

  PositionRouter: "0x36ab3442F6AD9CBb22E8543238E9559E27A01b94",
  ReferralStorage: "0x92F87E3A61Bded44fAf0668Da93543978Bf6b2F8",
  ReferralData: "0x94E4b3BAe70911eDa39f6CB5A160f2de82327A1B",
  ReferralReader: "0xfcd5ddd1B944910b94Dc232eA68b6bcd33D5c422",
  Timelock: "0x59829c5b2F011F93a3ac9771a674bEF0efc5e266",
  Slippage: "0xCa5a59AB5a61e030d75FC2C2c97a59D3b8876f25",

  Phase: "0xDF2f1eC9007963006680DDcd5E97B20f76A341A7",
  AuthV2: "0x046e8Ee6f7FB2c73eF8F70dd1c49467f6F6C49C9",
  FoundFactoryV2: "0xEE5D49b7ad6CBD313480F68C1502F3751Cc1C644",
  PoolDataV2: "0x2295f8aAE2264b7034A89D927ba2380c9AD75D61",
  LpCounter: "0xA082d162Df73d412DE4c1820EED4aa5a1eE30F34",
  ErrorContractV2: "0xD15c364675E6F4D161F7891c152c1C440084e199",
  FoundRouterV2: "0x9Dd0bc94cbce770B3039CbF417451B5Bb5f79E40",
  FoundReader: "0x778B2C7e7A8BD044D24BA56a25620C1c2524Ed2D",
  // 测试币领取
  MINT_AIRDROP: "0xDFcDCa8835f0Da2Ac48eC167F16FeB74EEE4C559",
  // Meme资金池
  MemeData: "0x566ed10fa0D6F4f602f6F2a0127925D9d3Cce5e6",
  MemeErrorContract: "0x6032024060841e2177A076cdA6bc8c15fBd5F64f",
  MemeFactory: "0x709C390003264D85b173803e560F6EbB59915064",
  MemeRouter: "0xa36a09A489316d43B68DD14d42401d5c820c669e",
  // Hyper 
  DeriwSubAccountPublic: "0x00000000000000000000000000000000000007E9",


  TestAuth: "0x6e6F13D2706D861F539925A5Af18893FB3D1D79C",
  TestPrpoFactory: "0xE9F045f0CE5dc1AD552e20E8df668194d67f95D5",
  TestPropTradeData: "0x305507D45D5441B81F5dD8FF9f00f65e0B392e86",
  TestErrContract: "0x3D343Fc0F6c6D2E047ec5e16e39A9b6A2031B9Ac",
  TestPropTradeRouter: "0x4D778dE09f5C043677bd18888114A9a0911dCE96",
  
  L1ToL2Router: "0x89b80e784F85028C75A00971B1A3f5827DC0d0aa",
  L2ToL3Router: "0xF66e0aBaba7e61968bF31fd75eEb39b379EEC954",
  L3ToL2Router: "0x09167d5D48bd6C95a50cce5E1B163a2017Ea1470",
  L2ToL1Router: "0x37C6A3b3C1E5b64071982dEfd092D8E02eC55118",
  UserL2ToL3Router: "0x9D51d0444c454d14b2bF6dE1E0b06D3fAB78C42e",
  UserL3ToL2Router: "0x9bC0eC414A47a41cF77452C2E667F4AfC2183146",
  ValidateSign: "0x6e465324A7d113389De95183Db8Cfb76d411625c",

  fundMinDeposit: {
    USDT: 10,
    ETH: 0.005,
    BNB: 0.044,
    UNI: 2,
    LINK: 1,
    UNISWAP: 2,
    WBTC: 0.0003,
    PENGU: 10
  }
};
