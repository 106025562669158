import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Logo from '../../assets/images/mint_button_logo.png'
import LogoLight from '../../assets/images/mint_button_logo_light.png'
import { Info, ShowToast, Warning } from "../Toast";
import { ethers } from "ethers";
import "./index.scss";
import { useTranslation } from "react-i18next";
import { useWeb3React } from "@web3-react/core";
import useWeb3ReactDeriw from "../../utils/wallets/useWeb3ReactDeriw";
import { getContract } from "../../config/contracts";
import { callContract, contractFetcher } from "../../utils/contracts";
import MintAirdropABI from "../../contracts/abi/MintAirdrop.json";
import ReaderABI from "../../contracts/abi/ReaderABI.json";
import { useChainId } from "../../utils/chains";
import { useDispatch, useSelector } from "react-redux";
import { setConnectionModal } from "../../store/subAccount/action";

const ToastId = "Toast_Transaction"

const MintButton = props => {
    const { t } = useTranslation();
    const { chainId } = useChainId();
    const dispatch = useDispatch();
    const mode = useSelector(state => state.mode);
    const { isSubConnected } = useSelector(state => state.subAccount)
    const [minting, setMinting] = useState(false)
    const { account, library } = useWeb3ReactDeriw()
    const contractAddress = getContract(chainId, "MINT_AIRDROP");

    const mint = async () => {
        if (!isSubConnected && account) {
            dispatch(setConnectionModal({isConnectionModal:true})) 
            return
         }
        if (!account) {
            ShowToast(ToastId, t('提示'), Warning, [t('无法获取地址')])
            return
        }
        if (minting) {
            return
        }
        setMinting(true)
        const contract = new ethers.Contract(contractAddress, MintAirdropABI, library.getSigner());
        const userInfo = await contract.getUserInfo(account)

        if (userInfo.lastTime.gt(0)) {
            ShowToast(ToastId, t('提示'), Warning, [t('已经领取')])
            return
        }
        ShowToast(ToastId, t('提示'), Warning, [t('铸造中')])
        
        callContract(chainId, contract, "claim", [], {
            pendingMsg: t(`铸造中`) + "...",
            sentMsg: t(`铸造中`) + "...",
            failMsg: t(`铸造失败`),
            successMsg: t(`mint_success`),
            hideSuccessMsg: false,
            gasless: true,
        }, null, contractAddress, MintAirdropABI).then(async (respond) => {
            if (respond && respond.wait) {
                await respond.wait()
            }
        }).catch((e) => {
            console.error("e", e)
        }).finally(() => {
            setMinting(false)
        })
    }

    if (!account) {
        return <></>
    }

    // if (userInfoData.lastTime && userInfoData.lastTime.gt(0)) {
    //     return <></>
    // }

    return (
        <div className="home_mint_button" onClick={mint}> <img
            src={mode.mode == 'dark' ? Logo : LogoLight}
            alt=""
            className=""
        />{t('测试代币')}</div>
    );
};

export default MintButton;
