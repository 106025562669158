import { Fragment, useCallback, useEffect, useState } from "react";
import { Menu, Transition, Dialog } from "@headlessui/react";
import { toggleBackDrop } from "../../utils/useBackDrop";
import activeDotIcon from "../../assets/images/check-small.png";
import { useTranslation } from "react-i18next";
import { addNetwork, switchNetwork } from "../../utils/wallets";
import { useChainId } from "../../utils/chains";
import { isDeriw } from "../../utils";
import { getChainName, getIcon, NETWORK_METADATA, NETWORK_OPTIONS } from "../../config/chains";
import arrowDown from "../../assets/images/down.png";
import ModelCloseM from "../../assets/images/model_close_m.png";
import ModelCloseMLight from "../../assets/images/model_close_m_light.png";
import { useSelector, useDispatch } from "react-redux";
import { screenIs } from "../../utils/theme"
import { useWeb3React } from "@web3-react/core";
import useWeb3ReactDeriw from "../../utils/wallets/useWeb3ReactDeriw";
import useNetworkDropdown, { toggleNetworkDropdown } from "../../utils/wallets/useNetworkDropdown";

import './index.scss'

export default function NetworkDropdown() {
  const [isSMScreen, setIsSMScreen] = useState(false)
  const mode = useSelector(state => state.mode);

  useEffect(() => {
    const _isSMScreen = screenIs("md")
    if (typeof _isSMScreen == "boolean") {
      setIsSMScreen(_isSMScreen)
    }
  }, [])

  const { chainId } = useChainId();
  
  const { active } = useWeb3ReactDeriw();
  const onNetworkSelect = useCallback(
    (option) => {
      if (option.value === chainId) {
        _setShowWalletModal(false)
        return;
      }
      return switchNetwork(option.value, active);
    },
    [chainId, active]
  );

  const _setShowWalletModal = (newState) => {
    toggleNetworkDropdown({ id: "WebNetworkDropdownModal", isShow: newState })
    return
  }

  async function handleNetworkSelect(option) {
    await onNetworkSelect(option);
  }

  const [isNetworkDropdownOn] = useNetworkDropdown()

  if (isSMScreen != false) {
    return (<Dropdown ></Dropdown>)
  } else {

    return <>
      <Transition appear show={isNetworkDropdownOn} as={Fragment}>
        <Dialog className="relative z-20" onClose={_setShowWalletModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center text-center network-dropdown-m">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="network-dropdown-m-box">
                  <Dialog.Title
                    className="text-lg font-semibold leading-6 text-gray-900"
                  >
                    <div className="flex flex-row justify-between" style={{ marginBottom: 24 }}>
                      <span className="model_title">{`Select Network`}</span>
                      <img onClick={() => _setShowWalletModal(false)} className="model_close" src={mode.mode == 'dark' ? ModelCloseM : ModelCloseMLight} alt={"closeWalletModal"} />
                    </div>
                  </Dialog.Title>
                  {
                    NETWORK_OPTIONS.map((network) => {
                      return <div className="network_options_m" key={network.label}>
                        <div className="network_options_m_left">
                          <img src={network.icon} />
                          {network.label}
                        </div>

                        {
                          chainId == network.value && <img src={activeDotIcon} alt="activeDot" className="activeDot" />
                        }
                      </div>

                      // <button
                      //   key={network.value}
                      //   type="button"
                      //   className="h-14 flex flex-row outline-0 justify-between rounded border border-transparent items-center px-4 py-2 text-lg font-semibold text-stone-950 hover:bg-slate-50 focus:outline-none "
                      //   onClick={() => { handleNetworkSelect({ value: network.value }) }}
                      // >
                      //   <div className="flex flex-row">
                      //     <div className="mr-1.5 flex flex-col justify-center">
                      //       <img className="w-6" src={network.icon} alt={network.label} />
                      //     </div>
                      //     <span className="font-medium ml-1.5  text-sm">{network.label}</span>
                      //   </div>
                      //   {
                      //     chainId == network.value && <div className="flex flex-col justify-center mr-2">
                      //       <img src={activeDotIcon} alt="activeDot" className="w-2 h-2 md:w-3 md:h-3" />
                      //     </div>
                      //   }
                      // </button>
                    })
                  }
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <div className={["selectchain_box", isDeriw(chainId) ? 'selectchain_deriw_bg' : ''].join(' ')}>

        <NavIcons showWalletModal={(s) => { _setShowWalletModal(s); return; }}></NavIcons>
      </div>
    </>
  }
}
function NavIcons({ showWalletModal }) {

  const [isSMScreen, setIsSMScreen] = useState(false)

  useEffect(() => {
    const _isSMScreen = screenIs("md")
    if (typeof _isSMScreen == "boolean") {
      setIsSMScreen(_isSMScreen)
    }
  }, [])

  const { chainId } = useChainId();
  const icon = getIcon(chainId || 0, "network");
  const { t } = useTranslation();

  return <div className="network-dropdown h-7 md:h-11 w-11 md:w-16 shrink-0 rounded-full flex flex-row items-center px-0 md:px-2 leading-3.5 bg-blueGrey hover:bg-lightPurple" onClick={() => showWalletModal ? showWalletModal(true) : null}>
    <img src={icon} alt="network" className="w-5 h-5 md:w-7 md:h-7" />
    {
      chainId > 0 ? '' : <div className="w-full whitespace-nowrap  text-greyBackground text-sm font-medium leading-4 mx-1">{t("连接钱包")}</div>
    }
    <div className="w-full md:mr-1"><img src={arrowDown} alt="arrowDown" className="mx-auto w-4 h-4 md:w-50 md:h-4" /></div>
  </div>
}
function Dropdown() {

  const { chainId } = useChainId();
  const { t } = useTranslation();
  return (
    <Menu>
      {({ open }) => {

        return <>
          <Control open={open}></Control>
          <Menu.Button className={["selectchain_box", isDeriw(chainId) ? 'selectchain_deriw_bg' : ""].join(' ')}>
            <NavIcons />
          </Menu.Button>

          {/* Use the `Transition` component. */}
          <Transition
            // className={"absolute z-20"}
            show={open}
          // enter="transition duration-100 ease-out"
          // enterFrom="transform scale-95 opacity-0"
          // enterTo="transform scale-100 opacity-100"
          // leave="transition duration-75 ease-out"
          // leaveFrom="transform scale-100 opacity-100"
          // leaveTo="transform scale-95 opacity-0"
          >
            <Menu.Items className="select_net_menu">
              {/* <div className="network-dropdown-title mx-2 my-2 title">
                {t('选择网络')}
              </div> */}
              <div className="">
                <NetworkMenuItems />
              </div>
            </Menu.Items>
          </Transition>
        </>
      }}
    </Menu>
  );
}

function NetworkMenuItems() {
  const { chainId } = useChainId();
  const { active } = useWeb3ReactDeriw();

  const onNetworkSelect = useCallback(
    (option) => {
      if (option.value === chainId) {
        const netData = JSON.parse(JSON.stringify(NETWORK_METADATA[chainId]))
        delete netData['nativeTokenSymbol']
        delete netData['defaultCollateralSymbol']
        delete netData['SWAP_ORDER_EXECUTION_GAS_FEE']
        delete netData['INCREASE_ORDER_EXECUTION_GAS_FEE']
        delete netData['DECREASE_ORDER_EXECUTION_GAS_FEE']
        addNetwork(netData);

      }
      return switchNetwork(option.value, active);
    },
    [chainId, active]
  );


  async function handleNetworkSelect(option) {
    await onNetworkSelect(option);
  }
  return <div className="w-full">
    {
      NETWORK_OPTIONS.map((network) => {
        return (

          <Menu.Item key={network.value}>
            <div className="select_net_menu_item" onClick={() => handleNetworkSelect({ value: network.value })}>
              <div className="left_box">
                <img className="icon_img" src={network.icon} alt={network.label} />
                <span className="font-medium ml-1.5  text-sm">{network.label}</span>
              </div>

              {
                chainId == network.value &&
                <img src={activeDotIcon} alt="activeDot" className="active_dot" />
              }
            </div>
          </Menu.Item>

        );
      })
    }
  </div>
}



function Control({ open }) {
  useEffect(() => {
    toggleBackDrop({ id: "NetworkDrodown", isShow: open })
  }, [open])
  return <div></div>
}
