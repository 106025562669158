import React, { useState, useEffect } from "react";
import CModal2 from "../common/CModal2";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { SET_MNEMONIC } from "../../store/mnemonic/action";
import { AES } from 'crypto-js';
import i18next from "i18next";
import { useWeb3React } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import { updateMnemonic } from "../../utils";
import walletBindLoadingImg from "../../assets/images/wallet_bind_loading.png";
import useWeb3ReactDeriw from "../../utils/wallets/useWeb3ReactDeriw";
import GenerateLoadingImg from '../../assets/images/generate_loading.png'
import RemembermeDarkImg from '../../assets/images/rememberme_dark.png'
import RemembermeLightImg from '../../assets/images/rememberme_light.png'
import GenerateSignYImg from '../../assets/images/generate_sign_y.png'
import httpApi from "../../api";
import EyesLightYImg from '../../assets/images/eyes_light_y.png'
import EyesLightNImg from '../../assets/images/eyes_light_n.png'
import InputClearLigntImg from '../../assets/images/input_clear_light.png'
import EyesDarkYImg from '../../assets/images/eyes_dark_y.png'
import EyesDarkNImg from '../../assets/images/eyes_dark_n.png'
import InputClearDarkImg from '../../assets/images/input_clear_dark.png'
import {  Info, ShowToast, Success, Warning  } from "../Toast";
import { SET_MNEMONIC_CONFIRM } from "../../store/mnemonicConfirm/action";
import { ethers, providers } from "ethers";
import {deriveHDKeyFromEthereumSignature , exportMnemonicAndPrivateKey} from '../../utils/privateKey'
import MetamaskIcon from '../../assets/images/metamask.png'
import {Switch, Tooltip} from 'antd'
import Web3 from 'web3'
import "./index.scss";
import { staticEncryptionKey, staticEncryptionPs } from "../../utils/legacy";
import config from "../../config";
const forge = require('node-forge');

const SecondaryModal = props => {
  const {onClose, visible, setMnemonicModal} = props
  const {account, library} = useWeb3React()
  const { t } = useTranslation();
  const mode = useSelector(state => state.mode);
  const mnemonicConfirm = useSelector(state => state.mnemonicConfirm);
  const dispatch = useDispatch();
  const [password, setPassword] = useState('')
  const [isShowPassword, setShowPassword] = useState(false)
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [isShowPasswordConfirm, setShowPasswordConfirm] = useState(false)
  const [mnemonicData, setMnemonicData] = useState({})

  const [isEnterPassword, setEnterPassword] = useState(false)

  const [sign1, setSign1] = useState('')
  const [sign1Loading, setSign1Loading] = useState(false)
  const [sign2Loading, setSign2Loading] = useState(false)
  const [isBind, setBind] = useState(false)
  const [isRememberMe, setRememberMe] = useState(false)
  const [isForgot, setForgot] = useState(false)
 
  const getLang = () => {
    return i18next.language === 'ZH'
  }

  const getIsBind = async() => {
    const address = account
    const res = await httpApi.getDydxWalletBind({main: address})
    if (res && res.data) {
      setBind(res.data.is_bind)
    }
    const res2 = await httpApi.getDydxWalletConfirm({main: address})
    if (res2 && res2.data) {
      dispatch({ type: SET_MNEMONIC_CONFIRM.type, payload: {is_confirm: res2.data.is_confirm ? true : false} })
    }
  }

  useEffect(() => {
    if (account) {
      getIsBind()
    }
  }, [account])

  useEffect(() => {
    if (visible && account) {
      const address = account
      if (address) {
        localStorage.setItem('needSecondary', address)
      }
    }
  }, [visible, account])

  const goToSign = async () => {
    if (sign1Loading) {
      return
    }
    setSign1Loading(true)
    try {
      // const signature_2 = await window.ethereum.signMessage({ 
      //   account,
      //   message: 'Deriw Chain',
      // }).then(async res => {
      const signature_1 = await library?.getSigner().signMessage('Deriw Chain').then(res => {
        if (res) {
          setEnterPassword(true)
          setSign1(res)
        }
        setSign1Loading(false)
      }).catch(e => {
        setSign1Loading(false)
      })
    } catch (e) {
      setSign1Loading(false)
    }
    // deriveHDKeyFromEthereumSignature , exportMnemonicAndPrivateKey
  }

  const createHmac = async() => {
    if (isForgot) {
      onClose()
    }
    if (!password || !sign1 || sign2Loading) {
      return
    }
    if (!isBind) {
      if (!passwordConfirm) {
        return
      }

      if (password !== passwordConfirm) {
        ShowToast('passwordConfirm', t("提示"), Warning, [t('两次密码输入不一致')])
        // ShowToast('passwordConfirm', t("提示"), Warning, [t("")])
        return
      }
    }

    if (password.length < 8) {
      ShowToast('passwordConfirm', t("提示"), Warning, [t('密码最少8位')])
      return
    }
    try {
console.log('%c sign1', 'color: #00A0E9; font-size: 26px; font-weight: blod;', sign1);
      // const provider = window.ethereum
      const provider = library.provider || window.ethereum
      var hmac = forge.hmac.create();
      hmac.start('sha256', password);
      hmac.update(sign1);
      const hex = hmac.digest().toHex()
      const mon = deriveHDKeyFromEthereumSignature(hex)
      const wallet = ethers.Wallet.fromMnemonic(mon.mnemonic);
      let signSecond = ''
  
      if (isBind) {
        const exampleMessageSecond = `${wallet.address} ${password}`
        const fromSecond = account;
        const msgSecond = `0x${Buffer.from(exampleMessageSecond, 'utf8').toString('hex')}`;
        const signSecondRes = await provider.request({
          method: 'personal_sign',
          params: [msgSecond, fromSecond, 'Example password'],
        });
        signSecond = signSecondRes
        const vRes = await httpApi.getDydxWalletVerify({main: account, second_sign: signSecond })
        if (vRes && vRes.data) {
          if (!vRes.data.is_verify) {
            ShowToast('passwordConfirm', t("提示"), Warning, [t('密码错误')])
            return
          }
        }
      }
      setSign2Loading(true)
      setMnemonicData(mon)
      setEnterPassword(false)
      const signature_2 = await library?.getSigner().signMessage('Deriw Chain').then(async res => {
        if (res) {
          if (sign1 == res) {
            const exampleMessage = `I confirm to bind the relationship from ${account} to ${wallet.address}`
            const from = account;
            const msg = `0x${Buffer.from(exampleMessage, 'utf8').toString('hex')}`;
            const sign = await provider.request({
              method: 'personal_sign',
              params: [msg, from, 'Example password'],
            });
            if (!isBind) {
              if (!signSecond) {
                const exampleMessageSecond = `${wallet.address} ${password}`
                const fromSecond = account;
                const msgSecond = `0x${Buffer.from(exampleMessageSecond, 'utf8').toString('hex')}`;
                const signSecondRes = await provider.request({
                  method: 'personal_sign',
                  params: [msgSecond, fromSecond, 'Example password'],
                });
                signSecond = signSecondRes
              }
              const postBindRes = await httpApi.dydxWalletBind({main: account, second: wallet.address, main_sign:sign, second_sign: signSecond })
            }
            if (isRememberMe) {
              const encryptSign = AES.encrypt(sign1, staticEncryptionKey).toString();
              const encryptPassword = AES.encrypt(password, staticEncryptionPs).toString();
              const data = {time: Math.trunc(Date.now() / 1000),  sign: encryptSign, password: encryptPassword}
              localStorage.setItem('mnemonic', JSON.stringify(data))
              updateMnemonic(data)
            }
            dispatch({ type: SET_MNEMONIC.type, payload: {...mon, mnemonicList: mon.mnemonic.split(' ')} });
            setSign2Loading(false)
            onClose()
            if (!mnemonicConfirm.is_confirm) {
              setMnemonicModal(true)
            }
          } else {
            throw new Error(
              'Your wallet does not support deterministic signing. Please switch to a different wallet provider.'
            );
          }
        }
      }).catch(e => {
        setSign2Loading(false)
      })
    } catch (e) {
console.log('%c eee', 'color: #00A0E9; font-size: 26px; font-weight: blod;', e);
    }
  }
  return (
    <CModal2
      onCancel={onClose}
      down={true}
      visible={visible}
      width={400}
      title={isEnterPassword ? (isBind ? t('输入密码'): t('设置密码')): <><img src={MetamaskIcon} className="secondary_metamask_icon"/>{t('签署消息')}</>}
    >
      {
        !isEnterPassword ?
        <div className="cancel_confirm_tx_secondary">
        <div className="confirm_tx_line">
          {t('签名用于验证您的所有权并确认钱包兼容性。新用户将收到四个签名请求。')}
        </div>
        {
          sign1 && mnemonicData.mnemonic ?
          <>
          
        <div className="generate generate3">
          <img src={GenerateSignYImg} className="generate_loading_img"/>
          <div className="generate_right">
            <div className="generate_right_top">
              {
                isBind ? 
                t('登录您的Deriw钱包') :
                t('生成您的Deriw钱包')
              }
              </div>
            {/* <div className="generate_right_bot">验证您是否拥有此钱包</div> */}
          </div>
        </div>
        <div className="generate generate2">
          <img src={walletBindLoadingImg
            // GenerateLoadingImg
            } className="generate_loading_img generate_loading_img_rotate"/>
          <div className="generate_right">
            <div className="generate_right_top">
            {
                isBind ? 
                t('登录您的Deriw钱包') :
                t('生成您的Deriw钱包')
              }
            </div>
            <div className="generate_right_bot">{t('判断钱包兼容性')}</div>
          </div>
        </div>
          </> : 
          <div className="generate">
            <img src={
              sign1Loading ?  walletBindLoadingImg : GenerateLoadingImg
              } className="generate_loading_img generate_loading_img_rotate"/>
            <div className="generate_right">
              <div className="generate_right_top">
                {
                isBind ? 
                t('登录您的Deriw钱包') :
                t('生成您的Deriw钱包')
              }
                </div>
              {/* <div className="generate_right_bot">验证您是否拥有此钱包</div> */}
            </div>
          </div>
        }
        <div className="confirm_tx_line2">
          <div className="rememberme">{t('记住我')} 
            <Tooltip title={t('仅在您使用属于自己的安全设备时才使用“记住我”。如果您使用的是公共设备或非安全设备，选择此项可能导致您的密钥和信息泄露给他人。')}>
              <img src={mode.mode == 'dark' ? RemembermeDarkImg : RemembermeLightImg}/>
            </Tooltip>
          </div>

          <Switch
            className="btn_switch"
            checked={isRememberMe} onChange={setRememberMe}
          />
        </div>

        <div className="confirm_tx_line3">
          {t('签名')}<span className="yes">{t('可用')}</span>{t('而且不会发送交易。')}
        </div>
        <div className="confirm_tx_btn" onClick={
          () => {
            if (mnemonicData.mnemonic) {
              createHmac()
            }else {
              goToSign()
            }
          } 
          }>{t('确认')}</div>
        <div className="confirm_tx_line4">
          {t('检查您的钱包，查看是否有新请求')}
          <br />
          {
            t('Metamask12.6.2可能导致无法授权，请升级版本')
          }
        </div>
      </div> : 
      <div className="cancel_confirm_tx_secondary">
        {
          isForgot ?
            <div className="confirm_tx_line5">
              {t('请使用多签钱包导入助记词')} <a className="a" href={config.wallet_example} target="_blank">{t('查看详情')}</a>
            </div> :
            <>
              <div className="confirm_tx_line5">
                {
                  isBind ? 
                  t('输入密码') :
                  t('初始密码')
                }
              </div>
              <div className="cancel_confirm_tx_secondary_inputbox">
                <input type={isShowPassword ? 'text' : 'password'} value={password} onChange={e => setPassword(e.target.value)} placeholder={isBind ? t('输入密码') : t('输入初始密码')} className="input" />
                {
                  password && <img className="clear" src={mode.mode == 'dark' ? InputClearDarkImg : InputClearLigntImg} onClick={() => setPassword('')}/>
                }
                {
                  isShowPassword ? 
                  <img onClick={() => setShowPassword(false)} className="eyes" src={mode.mode == 'dark' ? EyesDarkYImg : EyesLightYImg} /> : 
                  <img onClick={() => setShowPassword(true)} className="eyes" src={mode.mode == 'dark' ? EyesDarkNImg : EyesLightNImg} />
                }
              </div>

        {
          isBind ?
          <div className="confirm_tx_line5 forgot_password" onClick={() => setForgot(true)}>
            {t('忘记密码？')}
          </div>
            :
          <>
            <div className="confirm_tx_line5">
              {t('请再次输入')}
            </div>
            <div className="cancel_confirm_tx_secondary_inputbox">
              <input type={isShowPasswordConfirm ? 'text' : 'password'} value={passwordConfirm} onChange={e => setPasswordConfirm(e.target.value)} placeholder={t('请再次输入密码')} className="input" />
              {passwordConfirm && <img className="clear" src={mode.mode == 'dark' ? InputClearDarkImg : InputClearLigntImg} onClick={() => setPasswordConfirm('')}/>}
              {
                isShowPasswordConfirm ? 
                <img onClick={() => setShowPasswordConfirm(false)} className="eyes" src={mode.mode == 'dark' ? EyesDarkYImg : EyesLightYImg} /> : 
                <img onClick={() => setShowPasswordConfirm(true)} className="eyes" src={mode.mode == 'dark' ? EyesDarkNImg : EyesLightNImg} />
              }
            </div>
          </>
        }
            </>
        }
        <div className="confirm_tx_btn confirm_tx_btn2" onClick={createHmac}>{sign2Loading ? t('Pending') : t('确认')}</div>
      </div>
      }
      
    </CModal2>
  );
};

export default SecondaryModal;
