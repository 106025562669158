import { ethers } from "ethers";
const { parseEther } = ethers.utils;

export default {
  networkId: 35318034165, // 1:主网, 3：Ropsten测试链, 128: 火币主网,256: 火币测试链 42：kovan测试
  baseUrl: "https://api.test.deriw.com",
  testTransactionUrl: "https://test.deriw.com/trading-prop/trade",
  deriwTransactionUrl: "https://www.deriw.com/trading-prop/trade",

  aboutFundPoolDetailUrl: "https://docs.deriw.com/liquidity/liquidity-on-v1",
  klineUrl: "https://chart.test.deriw.com/",
  duneUrl: "https://dune.com/deriw_com/deriw-analytics",
  wsUrl: "wss://api.test.deriw.com/ws",
  explorer: "https://explorer.test.deriw.com/",
  tx: 'https://explorer.test.deriw.com/tx/',
  deriwExplorer: "https://explorer.deriw.com/",
  deriwTx: 'https://explorer.deriw.com/tx/',
  tokenAssetBaseUrl: "https://oss.deriw.com/tokens/",
  // tokenAssetBaseUrl: "https://fh-deriw.oss-ap-southeast-1.aliyuncs.com/tokens/",
  shareBaseUrl: "https://fh-dex.oss-ap-southeast-1.aliyuncs.com/fh-dex/client/invitation/",

  telegramgb: "https://t.me/deriwofficial",
  telegram: "https://t.me/deriwfinance",
  twitter: "https://twitter.com/DeriwFi",
  medium: "https://medium.com/@DeriwFi",
  discord: "https://discord.com/invite/deriwfinance",
  coinmarketcap: "https://coinmarketcap.com/community/profile/Deriw/",
  github: "https://github.com/deriwfi",
  docs: "https://docs.deriw.com",
  auditReport: "https://skynet.certik.com/projects/deriw-finance",
  wallet_example: "https://docs.deriw.com/security/recovering-your-deriw-wallet",
  sergentPrice: 99, // 100 usdt
  lieutenantPrice: 199, // 300 usdt
  chainName: 'Deriw Testnet',
  SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.0005"),
  INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0005"),
  netWorkIcon: "https://oss.deriw.com/chain/",

  ethRpc: "https://rpc.test.deriw.com",

  mainChainAddr: "0x0000000000000000000000000000000000000000", // 主链币是：BSC
  // mainChainAddr: "0x21007fbfe34972C9B968a4AB1AB94AfdE9170244", // 主链币是：BSC

  address0: "0x0000000000000000000000000000000000000000",
  UnBindReferral: "0x0000000000000000000000000000000000000000000000000000000000000000", // 邀请解除绑定传的地址
  isRepeatCode: "0x0000000000000000000000000000000000000000", // 生成邀请码时校验 等于0地址 邀请码就不重复

  Vault: "0xd8b5450Ec4fa699e1568FE4822b9C6bC44F5e0b1",
  Router: "0xc341cCD15cb8dC4e1020FC06EeF53aCb6010DDE1",
  VaultUtils: "0x33e25a86ACC644F31b560Bb02d5Dc620A0A2d5c7",
  VaultReader: "0x84b982952b15556aF6D7c8ba649Cc35C4A5b2284",
  Reader: "0xa407D100a5EeC6D17795B3dBe868751d1D7FFC6B",
  GlpManager: "0xf7862fEc11a268277D5c0AF34a4770135f6cBAA1",
  GlpRewardRouter: "0x4a822deE5b3AA5B9Df4B5399D7B7234824dBe53e",
  NATIVE_TOKEN: "0xb86b491dA10f9194C0C5c0B29cD1298fAf1A634A", // (WETH)
  GLP: "0x93a9b469F0509adDbeb798b541E12A43bA0d6166",
  OrderBook: "0x05b66aaC83fC4815A4B708A2C57B39d56c46c70b",
  OrderBookReader: "0x1Ca1B417E95A44F59E732DAFa49b698952B04703",

  PositionRouter: "0x9Fb76a6B771B39B1BC138C1e7b4a7a4E2a53cCD4",
  ReferralStorage: "0x964E2c1058eA3D5718E5658C697e687cb823d936",
  ReferralData: "0xc6201D30C99206fB5DFFf38E66EfD3753cc25e10",
  ReferralReader: "0xfcd5ddd1B944910b94Dc232eA68b6bcd33D5c422",
  Timelock: "0x4Eb3bBbA193Fb85e454C663c48bBC46A287810ee",

  Slippage: "0xfa4702Fd62206C6fF1B8Ea6fBb06AEF290506256",

  Phase: "0xc785C470A9ED0B1669375e434A03225797072f09",
  AuthV2: "0xd5D374d7989721aBFC78E862A285652f08DB1DA2",
  FoundFactoryV2: "0xCf1229cD1da55E53527e4d08c04C84c246816410",
  PoolDataV2: "0x73b35058Eeb5D5462e7A6befC21557b2B1fAF6a1",
  LpCounter: "0x606e188632f9A56c05D1179Cf5779B7cd9c7825C",
  ErrorContractV2: "0xB1a7A95707421DD01A304643Da3aE1C2B1bA4997",
  FoundRouterV2: "0x4Ce253Bd5255257AE96DE0Dda3f990b1Ed24e231",
  FoundReader: "0x904150FB2C31a443F88A760F93251FB3150842E8",

  TestAuth: "0x6e6F13D2706D861F539925A5Af18893FB3D1D79C",
  TestPrpoFactory: "0xE9F045f0CE5dc1AD552e20E8df668194d67f95D5",
  TestPropTradeData: "0x305507D45D5441B81F5dD8FF9f00f65e0B392e86",
  TestErrContract: "0x3D343Fc0F6c6D2E047ec5e16e39A9b6A2031B9Ac",
  TestPropTradeRouter: "0x4D778dE09f5C043677bd18888114A9a0911dCE96",
  
  L1ToL2Router: "0x89b80e784F85028C75A00971B1A3f5827DC0d0aa",
  L2ToL3Router: "0xF66e0aBaba7e61968bF31fd75eEb39b379EEC954",
  L3ToL2Router: "0x09167d5D48bd6C95a50cce5E1B163a2017Ea1470",
  L2ToL1Router: "0x37C6A3b3C1E5b64071982dEfd092D8E02eC55118",
  UserL2ToL3Router: "0x9D51d0444c454d14b2bF6dE1E0b06D3fAB78C42e",
  UserL3ToL2Router: "0x9bC0eC414A47a41cF77452C2E667F4AfC2183146",
  ValidateSign: "0x6e465324A7d113389De95183Db8Cfb76d411625c",

  fundMinDeposit: {
    USDT: 10,
    ETH: 0.005,
    BNB: 0.044,
    UNI: 2,
    LINK: 1,
    UNISWAP: 2,
    WBTC: 0.0003,
    PENGU: 10
  }
};

