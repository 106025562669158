import { useState, useCallback } from "react"
import { ethers } from "ethers"
import { useWeb3React } from "@web3-react/core"
import useHyperConnection from '../hooks/useHyperConnection'
import useWeb3ReactDeriw from '../utils/wallets/useWeb3ReactDeriw'

export default function useRandomPrivateKey() {
    const [subStorage, setSubStorage] = useState({})
    const { active, account } = useWeb3ReactDeriw()
    const { setHyperStorage } = useHyperConnection();
    const HYPER_USER_CONNECTION = `deriw_agent_${account}`
    
    const createSubAccount = useCallback(() => {
        if(active && account){
            const randomBytes = ethers.utils.randomBytes(32);
            const privateKey = ethers.utils.hexlify(randomBytes);
            const wallet = new ethers.Wallet(privateKey);
            const walletData = { privateKey, userAddress:account, agentAddress:wallet.address }
            setSubStorage(walletData)
            // SessionStorage缓存用户代理信息
            setHyperStorage(HYPER_USER_CONNECTION,walletData)
        }
    }, [active, account, setHyperStorage, HYPER_USER_CONNECTION])

    return {
        subStorage,
        createSubAccount
    }
}