import "./App.scss";
import Nav from "../src/components/Nav";
import BrowserNotification from "../src/components/BrowserNotification";
import Footer from "../src/components/Footer";
import { HashRouter, Route, Switch, Redirect, BrowserRouter } from "react-router-dom";
import React, { Suspense, useEffect } from "react";
import { useMedia } from "react-use";
import loadable from "./utils/loadable";
import { useSocket } from "./socket";
import { useWeb3React } from "@web3-react/core";
import useWeb3ReactDeriw from "./utils/wallets/useWeb3ReactDeriw";
import { useLoadTokens } from "./config/token";
import { intervalPost, leave } from "./utils/eventTracking";
import { useSelector, useDispatch } from "react-redux";
import { setSubAccount } from "./store/subAccount/action";
import { updateMnemonic } from "./utils";
import { ethers } from "ethers";
import { AES, enc } from 'crypto-js';
import { staticEncryptionKey, staticEncryptionPs } from "./utils/legacy";
import { deriveHDKeyFromEthereumSignature } from "./utils/privateKey";
const forge = require('node-forge');

const Home = loadable(() => import("./pages/home"));
const TransactionParams = loadable(() => import("./pages/transaction"));
const TransactionNone = loadable(() => import("./pages/transactionNone"));
const Earn = loadable(() => import("./pages/earn"));
const MemeEarn = loadable(() => import("./pages/memeEarn"));
const Ecology = loadable(() => import("./pages/ecology"));
const RecommendShare = loadable(() => import("./pages/recommend"));
const RecommendSetting = loadable(() => import("./pages/recommendSetting"));
// const FundTransaction = loadable(() => import("./pages/fundTransaction"));
const AboutFundPool = loadable(() => import("./pages/aboutFundPool"));
const EarnMy = loadable(() => import("./pages/earnMy"));
const MeMeEarnMy = loadable(() => import("./pages/memeEarnMy"));
const CreateChallenge = loadable(() => import("./pages/createChallenge"))
const CreateFund = loadable(() => import("./pages/createFund"))
const ChallengeDetail = loadable(() => import("./pages/challengeDetail"))
const Challenge = loadable(() => import("./pages/challenge"))
const CrossChain = loadable(() => import("./pages/crossChain"))
const TradingCompetition = loadable(() => import("./pages/tradingCompetition"))
const Dashboard = loadable(() => import("./pages/dashboard"))
const BountyHunter = loadable(() => import("./pages/bountyHunter"))
const BugCompetition = loadable(() => import("./pages/bugCompetition"))

const App = () => {
  const below960 = useMedia("(max-width: 960px)");
  const { tokens } = useLoadTokens()
  const { account, library } = useWeb3ReactDeriw()
  const dispatch = useDispatch();
  // 页面加载完 调用周期调用埋点方法
  // window.addEventListener('load', function () {
  //   intervalPost()

  useEffect(() => {
    if (library) {
      // 监听网络变化
      library.provider.on("networkChanged", network => {
        // if (window.location.href.indexOf('/crossChain') == -1) {
        //   if (document.visibilityState == 'visible') {
        //     window.location.reload();
        //   }
        // }
      });

      // 切换账号刷新
      library.provider.on('accountsChanged', function (accounts) {
        if (document.visibilityState == 'visible') {
          dispatch(setSubAccount({parentAccount:"", isSubConnected:false}))
          window.location.reload();
        }
        // loginSign(accounts[0])
      })
    } 
  }, [library])

  useEffect(() => {
    if (window.ethereum) {

      // 监听网络变化
      window.ethereum.on("networkChanged", network => {
        console.log('%c network', 'color: #00A0E9; font-size: 26px; font-weight: blod;', network);
        // if (window.location.href.indexOf('/crossChain') == -1) {
        //   if (document.visibilityState == 'visible') {
        //     window.location.reload();
        //   }
        // }
      });

      // 切换账号刷新
      // window.ethereum.on('accountsChanged', function (accounts) {
      //   if (document.visibilityState == 'visible') {
      //     window.location.reload();
      //     dispatch({ type: SET_MNEMONIC.type, payload: {} })
      //     localStorage.setItem('needSecondary', '')
      //   }

      //   // loginSign(accounts[0])
      // })
    }
  }, [])

  useSocket(account)
  // if (tokens.length == 0) {
  //   return <Suspense fallback={<div style={{ backgroundColor: localStorage.getItem('app_setting_theme') == 'light' ? '#fff' : '#16141A' }}>loading....</div>}>
  //   </Suspense>
  // }
  return (
    <Suspense fallback={<div style={{ backgroundColor: localStorage.getItem('app_setting_theme') == 'light' ? '#fff' : '#16141A' }}>loading....</div>}>
      <BrowserRouter>
        <div className="App" id="App">
          {/* <BrowserNotification /> */}
          <Nav />

          <div className="content" id="content">

            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/trade/:coin?" component={tokens.length == 0 ? TransactionNone : TransactionParams}/>
              
              <Route exact path="/earn/stacking" component={tokens.length == 0 ? TransactionNone : Earn} />
              <Route exact path="/earn/meme-stacking" component={tokens.length == 0 ? TransactionNone : MemeEarn} />
              <Route exact path="/ecosystem" component={Ecology} />
              <Route path="/referrals/:code?" component={RecommendShare}/>
              <Route exact path="/recommendSetting" component={RecommendSetting} />
              {/* <Route exact path="/fund/trade" component={FundTransaction} /> */}
              <Route exact path="/aboutFundPool" component={AboutFundPool} />
              <Route exact path="/earnMy" component={EarnMy} />
              <Route exact path="/meme-earnMy" component={MeMeEarnMy} />
              <Route exact path="/createChallenge" component={CreateChallenge} />
              <Route exact path="/createFund" component={CreateFund} />
              <Route exact path="/challengeDetail" component={ChallengeDetail} />
              <Route exact path="/challenge" component={Challenge} />
              <Route exact path="/crossChain" component={tokens.length == 0 ? TransactionNone : CrossChain} />
              <Route exact path="/event-hub/trading-competition" component={TradingCompetition} />
              <Route exact path="/dashboard" component={Dashboard} />
              <Route exact path="/event-hub/bonus-hunter" component={BountyHunter} />
              <Route exact path="/event-hub/bug-bounty" component={BugCompetition} />

              <Route exact path="/zh_cn" component={Home} />
              <Route path="/zh_cn/trade/:coin?" component={tokens.length == 0 ? TransactionNone : TransactionParams}/>
              <Route exact path="/zh_cn/earn/stacking" component={tokens.length == 0 ? TransactionNone : Earn} />
              <Route exact path="/zh_cn/earn/meme-stacking" component={tokens.length == 0 ? TransactionNone : MemeEarn} />
              <Route exact path="/zh_cn/ecosystem" component={Ecology} />
              <Route path="/zh_cn/referrals/:code?" component={RecommendShare}/>
              <Route exact path="/zh_cn/recommendSetting" component={RecommendSetting} />
              {/* <Route exact path="/zh_cn/fund/trade" component={FundTransaction} /> */}
              <Route exact path="/zh_cn/aboutFundPool" component={AboutFundPool} />
              <Route exact path="/zh_cn/earnMy" component={EarnMy} />
              <Route exact path="/zh_cn/meme-earnMy" component={MeMeEarnMy} />
              <Route exact path="/zh_cn/createChallenge" component={CreateChallenge} />
              <Route exact path="/zh_cn/createFund" component={CreateFund} />
              <Route exact path="/zh_cn/challengeDetail" component={ChallengeDetail} />
              <Route exact path="/zh_cn/challenge" component={Challenge} />
              <Route exact path="/zh_cn/crossChain" component={tokens.length == 0 ? TransactionNone : CrossChain} />
              <Route exact path="/zh_cn/event-hub/trading-competition" component={TradingCompetition} />
              <Route exact path="/zh_cn/dashboard" component={Dashboard} />
              <Route exact path="/zh_cn/event-hub/bonus-hunter" component={BountyHunter} />
              <Route exact path="/zh_cn/event-hub/bug-bounty" component={BugCompetition} />
              <Redirect to="/" />
            </Switch>
          </div>
          <Footer />
        </div>
      </BrowserRouter>
    </Suspense>
  );
};

export default App;
