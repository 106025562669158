import { bigNumberify, expandDecimals, formatAmount, formatAmountFixed } from "./numbers";
import { groupBy } from "lodash";
import { useWeb3React } from "@web3-react/core";
import useWeb3ReactDeriw from "./wallets/useWeb3ReactDeriw";
import { BigNumber, ethers } from "ethers";
import BigNumberJS from "bignumber.js";
import config from '../config'
import { getProvider } from "../utils/rpc";
import { USDG_ADDRESS, getTokenInfo, getUsd, replaceNativeTokenAddress } from '../utils'
import { getToken, getWhitelistedTokens } from "../config/token";
import PositionRouterABI from "../contracts/abi/PositionRouterABI.json"
import useSWR from "swr";
import { getServerBaseUrl, getServerUrl } from "../config/backend";
import { getConstant, getHighExecutionFee } from "../config/chains";
import { getContract } from "../config/contracts";
import { contractFetcher, callContract } from "../utils/contracts";
import OrderBookReaderABI from "../contracts/abi/OrderBookReader.json"
import OrderBookABI from "../contracts/abi/OrderBookABI.json"
import { useChainId } from "./chains";
import { useEffect } from "react";
import VaultV2ABI from "../contracts/abi/VaultABI.json"
import GlpManagerABI from "../contracts/abi/GlpManagerABI.json"
import i18next from "i18next";
import { isEmpty } from "lodash";
import DeriwSubAccountPublicABI from '../contracts/abi/DeriwSubAccountPublic.json'

const { AddressZero } = ethers.constants;

export const BASIS_POINTS_DIVISOR = 10000;
export const TRADE_FEE_BASIS_POINTS = 2;
export var MAX_LEVERAGE = 200 * BASIS_POINTS_DIVISOR;
export const MAX_ALLOWED_LEVERAGE = 100 * BASIS_POINTS_DIVISOR;
export const MAX_POSITIONS_ALLOWED_LEVERAGE = 200 * BASIS_POINTS_DIVISOR;
export const TRIGGER_PREFIX_ABOVE = ">";
export const TRIGGER_PREFIX_BELOW = "<";

export const MAX_PRICE_DEVIATION_BASIS_POINTS = 750;
export const DEFAULT_GAS_LIMIT = 1 * 1000 * 1000;
export const SECONDS_PER_YEAR = 31536000;
export const USDG_DECIMALS = 18;
export const USD_DECIMALS = 30;
export const DEPOSIT_FEE = 30;
export const DUST_BNB = "2000000000000000";
export const DUST_USD = expandDecimals(1, USD_DECIMALS);
export const PRECISION = expandDecimals(1, 30);
export const GLP_DECIMALS = 18;
export const GMX_DECIMALS = 18;
export const DEFAULT_MAX_USDG_AMOUNT = expandDecimals(200 * 1000 * 1000, 18);

export var TAX_BASIS_POINTS = 5;
export var STABLE_TAX_BASIS_POINTS = 5;
export var MINT_BURN_FEE_BASIS_POINTS = 5;
export var SWAP_FEE_BASIS_POINTS = 5;
export var MARGIN_FEE_BASIS_POINTS = 2;

export var LIQUIDATION_FEE = expandDecimals(2, USD_DECIMALS);

export const PLACEHOLDER_ACCOUNT = ethers.Wallet.createRandom().address;
export const TRADES_PAGE_SIZE = 100;

export var GLP_COOLDOWN_DURATION = 0;
export const THRESHOLD_REDEMPTION_VALUE = expandDecimals(993, 27); // 0.993
export const FUNDING_RATE_PRECISION = 1000000;

export const SWAP = "Swap";
export const INCREASE = "Increase";
export const DECREASE = "Decrease";
export const UNKNOWN = "Unknown";
export const LONG = "Long";
export const SHORT = "Short"

export const MARKET = "Market";
export const LIMIT = "Limit";
export const STOP = "Stop";

export const SWAP_OPTIONS = [LONG, SHORT];
export const SWAP_ORDER_OPTIONS = [MARKET, LIMIT];
export const LEVERAGE_ORDER_OPTIONS = [MARKET, LIMIT/*, STOP*/];

export const DEFAULT_HIGHER_SLIPPAGE_AMOUNT = 100;

export const MAX_REFERRAL_CODE_LENGTH = 20;
export const REFERRAL_CODE_QUERY_PARAM = "ref";

export var MIN_PROFIT_TIME = 0;
export const MIN_PROFIT_BIPS = 0;

export const staticEncryptionKey = "staticEncryptionKey"
export const staticEncryptionPs = "staticEncryptionPs"

export const HYPER_PERSIST_TRADING_CONNECTION = "persist_trading_connection"

export const EARN_TYPE = {
    DERIW : "deriw",
    MEME : "meme"
}

export let AllTokens = []
var _token = null

export function useLoadVariable() {

    const { library } = useWeb3ReactDeriw()
    const { chainId } = useChainId()

    const vaultAddress = getContract(chainId, "Vault");
    const glpAddress = getContract(chainId, "GlpManager");

    const { mutate: _maxLeverage } = useSWR(
        [`LoadVariable:maxLeverage:`, chainId, vaultAddress, "maxLeverage"],
        { fetcher: contractFetcher(library, VaultV2ABI) }
    );

    const { mutate: _marginFeeBasisPoints } = useSWR(
        [`LoadVariable:marginFeeBasisPoints:`, chainId, vaultAddress, "marginFeeBasisPoints"],
        { fetcher: contractFetcher(library, VaultV2ABI) }
    );

    const { mutate: _liquidationFeeUsd } = useSWR(
        [`LoadVariable:liquidationFeeUsd:`, chainId, vaultAddress, "liquidationFeeUsd"],
        { fetcher: contractFetcher(library, VaultV2ABI) }
    );

    const { mutate: _cooldownDuration } = useSWR(
        [`LoadVariable:cooldownDuration:`, chainId, glpAddress, "cooldownDuration"],
        { fetcher: contractFetcher(library, GlpManagerABI) }
    );

    const { mutate: _minProfitTime } = useSWR(
        [`LoadVariable:minProfitTime:`, chainId, vaultAddress, "minProfitTime"],
        { fetcher: contractFetcher(library, VaultV2ABI) }
    );

    useEffect(async () => {
        // const loadTime = localStorage.getItem("LoadVariable")
        // if (Date.now() < (loadTime + (60 * 60 * 24 * 1000))) {
        //     return
        // }
        try {
            var v1 = await _maxLeverage()
            MAX_LEVERAGE =  v1.toString()

            TAX_BASIS_POINTS = '0'
            STABLE_TAX_BASIS_POINTS = '0'
            MINT_BURN_FEE_BASIS_POINTS = '0'

            v1 = await _liquidationFeeUsd()
            LIQUIDATION_FEE = v1.toString()

            v1 = await _cooldownDuration()
            GLP_COOLDOWN_DURATION = v1.toString()

            v1 = await _marginFeeBasisPoints()
            MARGIN_FEE_BASIS_POINTS = v1.toString()

            v1 = await _minProfitTime()
            MIN_PROFIT_TIME = v1.toString()
        } catch (e) { console.log("e", e) }

        // localStorage.setItem("LoadVariable", Date.now() )
    }, [])

    return []
}

const adjustForDecimalsFactory = (n) => (number) => {
    if (n === 0) {
        return number;
    }
    if (n > 0) {
        return number.mul(expandDecimals(1, n));
    }
    return number.div(expandDecimals(1, -n));
};
export function adjustForDecimals(amount, divDecimals, mulDecimals) {
    return amount.mul(expandDecimals(1, mulDecimals)).div(expandDecimals(1, divDecimals));
}

export function getBuyGlpFromAmount(toAmount, fromTokenAddress, infoTokens, glpPrice, usdgSupply, totalTokenWeights) {
    const defaultValue = { amount: bigNumberify(0) };
    if (!toAmount || !fromTokenAddress || !infoTokens || !glpPrice || !usdgSupply || !totalTokenWeights) {
        return defaultValue;
    }

    const fromToken = getTokenInfo(infoTokens, fromTokenAddress);
    if (!fromToken || !fromToken.minPrice) {
        return defaultValue;
    }

    let fromAmount = toAmount.mul(glpPrice).div(fromToken.minPrice);
    fromAmount = adjustForDecimals(fromAmount, GLP_DECIMALS, fromToken.decimals);

    const usdgAmount = toAmount.mul(glpPrice).div(PRECISION);
    const feeBasisPoints = getFeeBasisPoints(
        fromToken,
        fromToken.usdgAmount,
        usdgAmount,
        MINT_BURN_FEE_BASIS_POINTS,
        TAX_BASIS_POINTS,
        true,
        usdgSupply,
        totalTokenWeights
    );

    fromAmount = fromAmount.mul(BASIS_POINTS_DIVISOR).div(BASIS_POINTS_DIVISOR - feeBasisPoints);

    return { amount: fromAmount, feeBasisPoints };
}

export function getSellGlpToAmount(toAmount, fromTokenAddress, infoTokens, glpPrice, usdgSupply, totalTokenWeights) {
    const defaultValue = { amount: bigNumberify(0) };
    if (!toAmount || !fromTokenAddress || !infoTokens || !glpPrice || !usdgSupply || !totalTokenWeights) {
        return defaultValue;
    }

    const fromToken = getTokenInfo(infoTokens, fromTokenAddress);
    if (!fromToken || !fromToken.maxPrice) {
        return defaultValue;
    }

    let fromAmount = toAmount.mul(glpPrice).div(fromToken.maxPrice);
    fromAmount = adjustForDecimals(fromAmount, GLP_DECIMALS, fromToken.decimals);

    const usdgAmount = toAmount.mul(glpPrice).div(PRECISION);

    // in the Vault contract, the USDG supply is reduced before the fee basis points
    // is calculated
    usdgSupply = usdgSupply.sub(usdgAmount);

    // in the Vault contract, the token.usdgAmount is reduced before the fee basis points
    // is calculated
    const feeBasisPoints = getFeeBasisPoints(
        fromToken,
        fromToken?.usdgAmount?.sub(usdgAmount),
        usdgAmount,
        MINT_BURN_FEE_BASIS_POINTS,
        TAX_BASIS_POINTS,
        false,
        usdgSupply,
        totalTokenWeights
    );

    fromAmount = fromAmount.mul(BASIS_POINTS_DIVISOR - feeBasisPoints).div(BASIS_POINTS_DIVISOR);

    return { amount: fromAmount, feeBasisPoints };
}
export function getSellGlpFromAmount(toAmount, swapTokenAddress, infoTokens, glpPrice, usdgSupply, totalTokenWeights) {
    const defaultValue = { amount: bigNumberify(0), feeBasisPoints: 0 };
    if (!toAmount || !swapTokenAddress || !infoTokens || !glpPrice || !usdgSupply || !totalTokenWeights) {
        return defaultValue;
    }

    const swapToken = getTokenInfo(infoTokens, swapTokenAddress);
    if (!swapToken || !swapToken.maxPrice) {
        return defaultValue;
    }

    let glpAmount = toAmount.mul(swapToken.maxPrice).div(glpPrice);
    glpAmount = adjustForDecimals(glpAmount, swapToken.decimals, USDG_DECIMALS);

    let usdgAmount = toAmount.mul(swapToken.maxPrice).div(PRECISION);
    usdgAmount = adjustForDecimals(usdgAmount, swapToken.decimals, USDG_DECIMALS);

    // in the Vault contract, the USDG supply is reduced before the fee basis points
    // is calculated
    usdgSupply = usdgSupply.sub(usdgAmount);

    // in the Vault contract, the token.usdgAmount is reduced before the fee basis points
    // is calculated
    const feeBasisPoints = getFeeBasisPoints(
        swapToken,
        swapToken?.usdgAmount?.sub(usdgAmount),
        usdgAmount,
        MINT_BURN_FEE_BASIS_POINTS,
        TAX_BASIS_POINTS,
        false,
        usdgSupply,
        totalTokenWeights
    );

    glpAmount = glpAmount.mul(BASIS_POINTS_DIVISOR).div(BASIS_POINTS_DIVISOR - feeBasisPoints);

    return { amount: glpAmount, feeBasisPoints };
}

export function getBuyGlpToAmount(fromAmount, swapTokenAddress, infoTokens, glpPrice, usdgSupply, totalTokenWeights) {
    const defaultValue = { amount: bigNumberify(0), feeBasisPoints: 0 };
    if (!fromAmount || !swapTokenAddress || !infoTokens || !glpPrice || !usdgSupply || !totalTokenWeights) {
        return defaultValue;
    }

    const swapToken = getTokenInfo(infoTokens, swapTokenAddress);
    if (!swapToken || !swapToken.minPrice) {
        return defaultValue;
    }
    let glpAmount = fromAmount.mul(swapToken.minPrice).div(glpPrice);
    glpAmount = adjustForDecimals(glpAmount, swapToken.decimals, USDG_DECIMALS);

    let usdgAmount = fromAmount.mul(swapToken.minPrice).div(PRECISION);
    usdgAmount = adjustForDecimals(usdgAmount, swapToken.decimals, USDG_DECIMALS);
    const feeBasisPoints = getFeeBasisPoints(
        swapToken,
        swapToken.usdgAmount,
        usdgAmount,
        MINT_BURN_FEE_BASIS_POINTS,
        TAX_BASIS_POINTS,
        true,
        usdgSupply,
        totalTokenWeights
    );
    glpAmount = glpAmount.mul(BASIS_POINTS_DIVISOR - feeBasisPoints).div(BASIS_POINTS_DIVISOR);

    return { amount: glpAmount, feeBasisPoints };
}


export function getFeeBasisPoints(
    token,
    feeBasisPoints,
    taxBasisPoints,
    increment,
    usdgSupply,
    totalTokenWeights
) {
    return 0;
}

export function useAccountOrders(flagOrdersEnabled, overrideAccount) {
    const { library, account: connectedAccount } = useWeb3ReactDeriw();
    const active = true; // this is used in Actions.js so set active to always be true
    const account = overrideAccount || connectedAccount;

    const { chainId } = useChainId();
    const shouldRequest = active && account && flagOrdersEnabled;

    const orderBookAddress = getContract(chainId, "OrderBook");
    const orderBookReaderAddress = getContract(chainId, "OrderBookReader");
    const key = shouldRequest ? [active, chainId, orderBookAddress, account] : false;
    const {
        data: orders = [],
        mutate: updateOrders,
        error: ordersError,
    } = useSWR(key, {
        dedupingInterval: 5000,
        fetcher: async ([active, chainId, orderBookAddress, account]) => {

            const provider = getProvider(undefined, chainId);

            const orderBookContract = new ethers.Contract(orderBookAddress, OrderBookABI, provider);

            const orderBookReaderContract = new ethers.Contract(orderBookReaderAddress, OrderBookReaderABI, provider);

            const fetchIndexesFromServer = () => {
                const ordersIndexesUrl = `${getServerBaseUrl(chainId)}client/order/indices?address=${account}`;
                return fetch(ordersIndexesUrl)
                    .then(async (res) => {
                        const json1 = await res.json();
                        const json = json1.data;
                        const ret = {};
                        for (const key of Object.keys(json)) {
                            ret[key.toLowerCase()] = json[key].map((val) => parseInt(val)).sort((a, b) => a - b);
                        }

                        return ret;
                    })
                    .catch(() => ({ increase: [], decrease: [] }));
            };

            const fetchLastIndex = async (type) => {
                const method = type.toLowerCase() + "OrdersIndex";
                return await orderBookContract[method](account).then((res) => bigNumberify(res._hex).toNumber());
            };

            const fetchLastIndexes = async () => {
                const [increase, decrease] = await Promise.all([
                    fetchLastIndex("increase"),
                    fetchLastIndex("decrease"),
                ]);

                return { increase, decrease };
            };

            const getRange = (to, from) => {
                const LIMIT = 10;
                const _indexes = [];
                from = from || Math.max(to - LIMIT, 0);
                for (let i = to - 1; i >= from; i--) {
                    _indexes.push(i);
                }
                return _indexes;
            };

            const getIndexes = (knownIndexes, lastIndex) => {
                if (knownIndexes.length === 0) {
                    return getRange(lastIndex);
                }
                return [
                    ...knownIndexes,
                    ...getRange(lastIndex, knownIndexes[knownIndexes.length - 1] + 1).sort((a, b) => b - a),
                ];
            };

            const getOrders = async (method, knownIndexes, lastIndex, parseFunc) => {
                const indexes = getIndexes(knownIndexes, lastIndex);
                const ordersData = await orderBookReaderContract[method](orderBookAddress, account, indexes);
                const orders = parseFunc(chainId, ordersData, account, indexes);

                return orders;
            };
            try {
                const [serverIndexes, lastIndexes] = await Promise.all([fetchIndexesFromServer(), fetchLastIndexes()]);
                const [increaseOrders = [], decreaseOrders = []] = await Promise.all([
                    getOrders("getIncreaseOrders", serverIndexes.increase, lastIndexes.increase, parseIncreaseOrdersData),
                    getOrders("getDecreaseOrders", serverIndexes.decrease, lastIndexes.decrease, parseDecreaseOrdersData),
                ]);
                return [...increaseOrders, ...decreaseOrders];
            } catch (ex) {
                // eslint-disable-next-line no-console
                console.error(ex);
            }
        },
    });

    return [orders, updateOrders, ordersError];
}


export function getLeverageStr(leverage) {
    if (leverage && BigNumber.isBigNumber(leverage)) {
        if (leverage.lt(0)) {
            return "> 100x";
        }
        return `${formatAmountFixed(leverage, 4, 2, true)}X`;
    }
}

export function getMarginFee(sizeDelta) {
    if (!sizeDelta) {
        return bigNumberify(0);
    }
    const afterFeeUsd = sizeDelta.mul(BASIS_POINTS_DIVISOR - MARGIN_FEE_BASIS_POINTS).div(BASIS_POINTS_DIVISOR);
    return sizeDelta.sub(afterFeeUsd);
}

export function getLeverage({ size, collateral, fundingFee, hasProfit, delta, includeDelta, totalFee,  symbol = '', isLong = false }) {
    if (!size || !collateral) {
        return;
    }

    let remainingCollateral = collateral.add(totalFee || bigNumberify(0));

    if (fundingFee && fundingFee.gt(0)) {
        remainingCollateral = remainingCollateral.sub(fundingFee);
    }

    if (delta && includeDelta) {
        if (hasProfit) {
            remainingCollateral = remainingCollateral.add(delta);
        } else {
            if (delta.gt(remainingCollateral)) {
                return;
            }
            remainingCollateral = remainingCollateral.sub(delta);
        }
    }

    if (remainingCollateral.eq(0)) {
        return;
    }

    const leverage = size.mul(BASIS_POINTS_DIVISOR).div(remainingCollateral); 

    return leverage;
}
   
export function getDeltaStr({ delta, deltaPercentage, hasProfit }) {
    let deltaStr;
    let deltaPercentageStr;

    if (delta.gt(0)) {
        deltaStr = hasProfit ? "+" : "-";
        deltaPercentageStr = hasProfit ? "+" : "-";
    } else {
        deltaStr = "";
        deltaPercentageStr = "";
    }
    deltaStr += `$${formatAmount(delta, 30, 2, true)}`;
    deltaPercentageStr += `${formatAmount(deltaPercentage, 2, 2)}%`;

    return { deltaStr, deltaPercentageStr };
}

export function getPositionContractKey(account, collateralToken, indexToken, isLong) {
    return ethers.utils.solidityKeccak256(
        ["address", "address", "address", "bool"],
        [account, collateralToken, indexToken, isLong]
    );
}
export function getFundingFee(data) {
    return 0
    let { entryFundingRate, cumulativeFundingRate, size } = data;

    if (entryFundingRate && cumulativeFundingRate) {
        return size.mul(cumulativeFundingRate.sub(entryFundingRate)).div(1000000);
    }

    return;
}
export function getPositionKey(
    account,
    collateralTokenAddress,
    indexTokenAddress,
    isLong,
    nativeTokenAddress
) {
    const tokenAddress0 = collateralTokenAddress === AddressZero ? nativeTokenAddress : collateralTokenAddress;
    const tokenAddress1 = indexTokenAddress === AddressZero ? nativeTokenAddress : indexTokenAddress;
    return account + ":" + tokenAddress0 + ":" + tokenAddress1 + ":" + isLong;
}

export function getMostAbundantStableToken(chainId, infoTokens) {
    const whitelistedTokens = getWhitelistedTokens(chainId);
    let availableAmount;
    let stableToken = whitelistedTokens.find((t) => t.isStable);

    for (let i = 0; i < whitelistedTokens.length; i++) {
        const info = infoTokens[whitelistedTokens[i].address]
        if (!info.isStable || !info.availableAmount) {
            continue;
        }

        const adjustedAvailableAmount = adjustForDecimals(info.availableAmount, info.decimals, USD_DECIMALS);
        if (!availableAmount || adjustedAvailableAmount.gt(availableAmount)) {
            availableAmount = adjustedAvailableAmount;
            stableToken = info;
        }
    }

    return stableToken;
}

export function getNextToAmount(
    chainId,
    fromAmount,
    fromTokenAddress,
    toTokenAddress,
    infoTokens,
    toTokenPriceUsd,
    ratio,
    totalTokenWeights,
    forSwap
) {
    const defaultValue = { amount: bigNumberify(0) };
    if (!fromAmount || !fromTokenAddress || !toTokenAddress || !infoTokens) {
        return defaultValue;
    }

    if (fromTokenAddress === toTokenAddress) {
        return { amount: fromAmount };
    }

    const fromToken = getTokenInfo(infoTokens, fromTokenAddress);
    const toToken = getTokenInfo(infoTokens, toTokenAddress);

    if (fromToken.isNative && toToken.isWrapped) {
        return { amount: fromAmount };
    }

    if (fromToken.isWrapped && toToken.isNative) {
        return { amount: fromAmount };
    }

    // the realtime price should be used if it is for a transaction to open / close a position
    // or if the transaction involves doing a swap and opening / closing a position
    // otherwise use the contract price instead of realtime price for swaps

    let fromTokenMinPrice;
    if (fromToken) {
        fromTokenMinPrice = forSwap ? fromToken.contractMinPrice : fromToken.minPrice;
    }

    let toTokenMaxPrice;
    if (toToken) {
        toTokenMaxPrice = forSwap ? toToken.contractMaxPrice : toToken.maxPrice;
    }

    if (!fromTokenMinPrice || !toTokenMaxPrice) {
        return defaultValue;
    }

    const adjustDecimals = adjustForDecimalsFactory(toToken.decimals - fromToken.decimals);

    let toAmountBasedOnRatio = bigNumberify(0);
    if (ratio && !ratio.isZero()) {
        toAmountBasedOnRatio = fromAmount.mul(PRECISION).div(ratio);
    }

    if (toTokenAddress === USDG_ADDRESS) {
        const feeBasisPoints = 0;

        if (ratio && !ratio.isZero()) {
            const toAmount = toAmountBasedOnRatio;
            return {
                amount: adjustDecimals(toAmount.mul(BASIS_POINTS_DIVISOR - feeBasisPoints).div(BASIS_POINTS_DIVISOR)),
                feeBasisPoints,
            };
        }

        const toAmount = fromAmount.mul(fromTokenMinPrice).div(PRECISION);
        return {
            amount: adjustDecimals(toAmount.mul(BASIS_POINTS_DIVISOR - feeBasisPoints).div(BASIS_POINTS_DIVISOR)),
            feeBasisPoints,
        };
    }

    if (fromTokenAddress === USDG_ADDRESS) {
        const redemptionValue = toToken.redemptionAmount
            ?.mul(toTokenPriceUsd || toTokenMaxPrice)
            .div(expandDecimals(1, toToken.decimals));

        if (redemptionValue && redemptionValue.gt(THRESHOLD_REDEMPTION_VALUE)) {
            const feeBasisPoints = 0;

            const toAmount =
                ratio && !ratio.isZero()
                    ? toAmountBasedOnRatio
                    : fromAmount.mul(toToken.redemptionAmount).div(expandDecimals(1, toToken.decimals));

            return {
                amount: adjustDecimals(toAmount.mul(BASIS_POINTS_DIVISOR - feeBasisPoints).div(BASIS_POINTS_DIVISOR)),
                feeBasisPoints,
            };
        }

        const expectedAmount = fromAmount;

        const stableToken = getMostAbundantStableToken(chainId, infoTokens);
        if (!stableToken?.availableAmount || stableToken.availableAmount.lt(expectedAmount)) {
            const toAmount =
                ratio && !ratio.isZero()
                    ? toAmountBasedOnRatio
                    : fromAmount.mul(toToken.redemptionAmount).div(expandDecimals(1, toToken.decimals));
            const feeBasisPoints = 0;
            return {
                amount: adjustDecimals(toAmount.mul(BASIS_POINTS_DIVISOR - feeBasisPoints).div(BASIS_POINTS_DIVISOR)),
                feeBasisPoints,
            };
        }

        const feeBasisPoints0 = 0
        const feeBasisPoints1 = 0

        if (ratio && !ratio.isZero()) {
            const toAmount = toAmountBasedOnRatio
                .mul(BASIS_POINTS_DIVISOR - feeBasisPoints0 - feeBasisPoints1)
                .div(BASIS_POINTS_DIVISOR);
            return {
                amount: adjustDecimals(toAmount),
                path: [USDG_ADDRESS, stableToken.address, toToken.address],
                feeBasisPoints: feeBasisPoints0 + feeBasisPoints1,
            };
        }

        // get toAmount for USDG => stableToken
        let toAmount = fromAmount.mul(PRECISION).div(stableToken.maxPrice);
        // apply USDG => stableToken fees
        toAmount = toAmount.mul(BASIS_POINTS_DIVISOR - feeBasisPoints0).div(BASIS_POINTS_DIVISOR);

        // get toAmount for stableToken => toToken
        toAmount = toAmount.mul(stableToken.minPrice).div(toTokenPriceUsd || toTokenMaxPrice);
        // apply stableToken => toToken fees
        toAmount = toAmount.mul(BASIS_POINTS_DIVISOR - feeBasisPoints1).div(BASIS_POINTS_DIVISOR);

        return {
            amount: adjustDecimals(toAmount),
            path: [USDG_ADDRESS, stableToken.address, toToken.address],
            feeBasisPoints: feeBasisPoints0 + feeBasisPoints1,
        };
    }

    const toAmount =
        ratio && !ratio.isZero()
            ? toAmountBasedOnRatio
            : fromAmount.mul(fromTokenMinPrice).div(toTokenPriceUsd || toTokenMaxPrice);

    let usdgAmount = fromAmount.mul(fromTokenMinPrice).div(PRECISION);
    usdgAmount = adjustForDecimals(usdgAmount, fromToken.decimals, USDG_DECIMALS);

    const taxBasisPoints = fromToken.isStable && toToken.isStable ? STABLE_TAX_BASIS_POINTS : TAX_BASIS_POINTS;
    const feeBasisPoints0 = getFeeBasisPoints(
        fromToken,
        fromToken.usdgAmount,
        usdgAmount,
        0,
        taxBasisPoints,
        true,
        totalTokenWeights
    );
    const feeBasisPoints1 = getFeeBasisPoints(
        toToken,
        toToken.usdgAmount,
        usdgAmount,
        0,
        taxBasisPoints,
        false,
        totalTokenWeights
    );
    const feeBasisPoints = feeBasisPoints0 > feeBasisPoints1 ? feeBasisPoints0 : feeBasisPoints1;

    return {
        amount: adjustDecimals(toAmount.mul(BASIS_POINTS_DIVISOR - feeBasisPoints).div(BASIS_POINTS_DIVISOR)),
        feeBasisPoints,
    };
}

export function getNextFromAmount(
    chainId,
    toAmount,
    fromTokenAddress,
    toTokenAddress,
    infoTokens,
    toTokenPriceUsd,
    ratio,
    totalTokenWeights,
    forSwap
) {
    const defaultValue = { amount: bigNumberify(0) };

    if (!toAmount || !fromTokenAddress || !toTokenAddress || !infoTokens) {
        return defaultValue;
    }

    if (fromTokenAddress === toTokenAddress) {
        return { amount: toAmount };
    }

    const fromToken = getTokenInfo(infoTokens, fromTokenAddress);
    const toToken = getTokenInfo(infoTokens, toTokenAddress);

    if (fromToken.isNative && toToken.isWrapped) {
        return { amount: toAmount };
    }

    if (fromToken.isWrapped && toToken.isNative) {
        return { amount: toAmount };
    }

    // the realtime price should be used if it is for a transaction to open / close a position
    // or if the transaction involves doing a swap and opening / closing a position
    // otherwise use the contract price instead of realtime price for swaps

    let fromTokenMinPrice;
    if (fromToken) {
        fromTokenMinPrice = forSwap ? fromToken.contractMinPrice : fromToken.minPrice;
    }

    let toTokenMaxPrice;
    if (toToken) {
        toTokenMaxPrice = forSwap ? toToken.contractMaxPrice : toToken.maxPrice;
    }

    if (!fromToken || !fromTokenMinPrice || !toToken || !toTokenMaxPrice) {
        return defaultValue;
    }

    const adjustDecimals = adjustForDecimalsFactory(fromToken.decimals - toToken.decimals);

    let fromAmountBasedOnRatio;
    if (ratio && !ratio.isZero()) {
        fromAmountBasedOnRatio = toAmount.mul(ratio).div(PRECISION);
    }

    const fromAmount =
        ratio && !ratio.isZero() ? fromAmountBasedOnRatio : toAmount.mul(toTokenMaxPrice).div(fromTokenMinPrice);

    let usdgAmount = fromAmount.mul(fromTokenMinPrice).div(PRECISION);
    usdgAmount = adjustForDecimals(usdgAmount, toToken.decimals, USDG_DECIMALS);

    const taxBasisPoints = fromToken.isStable && toToken.isStable ? STABLE_TAX_BASIS_POINTS : TAX_BASIS_POINTS;
    const feeBasisPoints0 = getFeeBasisPoints(
        fromToken,
        fromToken.usdgAmount,
        usdgAmount,
        0,
        taxBasisPoints,
        true,
        totalTokenWeights
    );
    const feeBasisPoints1 = getFeeBasisPoints(
        toToken,
        toToken.usdgAmount,
        usdgAmount,
        0,
        taxBasisPoints,
        false,
        totalTokenWeights
    );
    const feeBasisPoints = feeBasisPoints0 > feeBasisPoints1 ? feeBasisPoints0 : feeBasisPoints1;

    return {
        amount: adjustDecimals(fromAmount.mul(BASIS_POINTS_DIVISOR).div(BASIS_POINTS_DIVISOR - feeBasisPoints)),
        feeBasisPoints,
    };
}

//======== orders


function parseIncreaseOrdersData(chainId, increaseOrdersData, account, indexes) {
    const extractor = (sliced) => {
        const isLong = sliced[5].toString() === "1";
        return {
            purchaseToken: sliced[0],
            collateralToken: sliced[1],
            indexToken: sliced[2],
            purchaseTokenAmount: sliced[3],
            sizeDelta: sliced[4],
            isLong,
            triggerPrice: sliced[6],
            triggerAboveThreshold: sliced[7].toString() === "1",
            type: INCREASE,
            lever: sliced[8],
            time: sliced[9],
        };
    };
    return _parseOrdersData(increaseOrdersData, account, indexes, extractor, 7, 3).filter((order) => {
        return (
            isValidToken(chainId, order.purchaseToken) &&
            isValidToken(chainId, order.collateralToken) &&
            isValidToken(chainId, order.indexToken)
        );
    });
}


export function _parseOrdersData(ordersData, account, indexes, extractor, uintPropsLength, addressPropsLength) {
    if (!ordersData || !ordersData["0"]) {
        return [];
    }
    const { 0: uintProps, 1: addressProps } = ordersData;
    const count = uintProps.length / uintPropsLength;

    const orders = [];
    for (let i = 0; i < count; i++) {
        const sliced = addressProps
            .slice(addressPropsLength * i, addressPropsLength * (i + 1))
            .concat(uintProps.slice(uintPropsLength * i, uintPropsLength * (i + 1)));

        if (sliced[0] === AddressZero && sliced[1] === AddressZero) {
            continue;
        }

        const order = extractor(sliced);
        order.index = indexes[i];
        order.account = account;
        orders.push(order);
    }

    return orders;
}

export function parseDecreaseOrdersData(chainId, decreaseOrdersData, account, indexes) {
    const extractor = (sliced) => {
        const isLong = sliced[4].toString() === "1";
        return {
            collateralToken: sliced[0],
            indexToken: sliced[1],
            collateralDelta: sliced[2],
            sizeDelta: sliced[3],
            isLong,
            triggerPrice: sliced[5],
            triggerAboveThreshold: sliced[6].toString() === "1",
            type: DECREASE,
            lever: sliced[7],
            time: sliced[8],
        };
    };
    return _parseOrdersData(decreaseOrdersData, account, indexes, extractor, 7, 2).filter((order) => {
        return isValidToken(chainId, order.collateralToken) && isValidToken(chainId, order.indexToken);
    });
}

export function parseSwapOrdersData(chainId, swapOrdersData, account, indexes) {
    if (!swapOrdersData || !swapOrdersData.length) {
        return [];
    }

    const extractor = (sliced) => {

        const triggerAboveThreshold = sliced[6].toString() === "1";
        const shouldUnwrap = sliced[7].toString() === "1";

        return {
            path: [sliced[0], sliced[1], sliced[2]].filter((address) => address !== AddressZero),
            amountIn: sliced[3],
            minOut: sliced[4],
            triggerRatio: sliced[5],
            triggerAboveThreshold,
            type: SWAP,
            shouldUnwrap,
        };
    };
    return _parseOrdersData(swapOrdersData, account, indexes, extractor, 6, 3).filter((order) => {
        return order.path.every((token) => isValidToken(chainId, token));
    });
}

export function parseOrderType(_order) {
    if (_order.type == INCREASE) {
        if (_order.isLong == true) {
            return { name: LONG, color: "" }
        } else if (_order.isLong == false) {
            return { name: SHORT, color: "dot_r" }
        }

    } else if (_order.type == DECREASE) {
        if (_order.isLong == true) {
            return { name: LONG, color: "#00BB8F" }
        } else if (_order.isLong == false) {
            return { name: SHORT, color: "dot_r" }
        }
    } else if (_order.type == SWAP) {
        return { name: SWAP, color: "" }
    }
    return { name: UNKNOWN, color: "" }
}

//======== orders

//======== tokens
export function isValidToken(chainId, address) {

    if (getToken(chainId, address)) {
        return true
    }
    return false
}

export function findToken(chainId, address) {
    const _token = getToken(chainId, address)
    if (_token) {
        return _token
    }
    return null
}
//======== tokens

//======== positions

export const getPositions = (
    chainId,
    positionQuery,
    positionData,
    tokenInfo,
    includeDelta,
    showPnlAfterFees,
    account,
    pendingPositions,
    updatedPositions,
    firstPosition,
    positionSortData,
    positionFeeData,
    positionTotalSizeDeltaData
) => {
    // const { library } = useWeb3React()

    // const vaultAddress = getContract(chainId, "Vault");

    // const { data: marginFeeBasisPoints } = useSWR(
    //     [`LoadVariable:marginFeeBasisPoints:`, chainId, vaultAddress, "marginFeeBasisPoints"],
    //     { fetcher: contractFetcher(library, VaultV2ABI) }
    // );

    const propsLength = 9;
    const positions = [];
    const positionFilterList = [
        { value: '', label: i18next.t("全部") },
    ]
    const positionsMap = {};
    if (!positionData) {
        return { positions, positionsMap };
    }
    const { collateralTokens, indexTokens, isLong } = positionQuery;

    for (let i = 0; i < collateralTokens.length; i++) {
        const collateralToken = tokenInfo[collateralTokens[i]]
        const indexToken = tokenInfo[indexTokens[i]]
        const key = getPositionKey(account, collateralTokens[i], indexTokens[i], isLong[i]);
        let contractKey;
        if (account) {
            contractKey = getPositionContractKey(account, collateralTokens[i], indexTokens[i], isLong[i]);
        }
        const position = {
            usedSize: bigNumberify(0),
            availableSize: positionData[i * propsLength],
            key,
            contractKey,
            collateralToken,
            indexToken,
            isLong: isLong[i],
            size: positionData[i * propsLength],
            collateral: positionData[i * propsLength + 1],
            averagePrice: positionData[i * propsLength + 2],
            entryFundingRate: positionData[i * propsLength + 3],
            cumulativeFundingRate: collateralToken && collateralToken.cumulativeFundingRate ? collateralToken.cumulativeFundingRate : bigNumberify(0),
            hasRealisedProfit: positionData[i * propsLength + 4].eq(1),
            realisedPnl: positionData[i * propsLength + 5],
            lastIncreasedTime: positionData[i * propsLength + 6].toString(),
            hasProfit: positionData[i * propsLength + 7].eq(1),
            delta: positionData[i * propsLength + 8],
            markPrice: isLong[i] ?
                indexToken && indexToken.minPrice ? indexToken.minPrice : bigNumberify(0) :
                indexToken && indexToken.maxPrice ? indexToken.maxPrice : bigNumberify(0)
        };

        if (
            updatedPositions &&
            updatedPositions[key] &&
            updatedPositions[key].updatedAt &&
            updatedPositions[key].updatedAt + (60 * 1000) > Date.now()
        ) {
            const updatedPosition = updatedPositions[key];
            position.size = updatedPosition.size;
            position.collateral = updatedPosition.collateral;
            position.averagePrice = updatedPosition.averagePrice;
            position.entryFundingRate = updatedPosition.entryFundingRate;
        }


        position.fundingFee = bigNumberify(0);
        position.collateralAfterFee = position.collateral.sub(position.fundingFee);

        position.closingFee = bigNumberify(0)
        position.positionFee = bigNumberify(0)
        if (position.size != 0) {
            const afterFeeUsd = position.size.mul(bigNumberify(BASIS_POINTS_DIVISOR).sub(bigNumberify(MARGIN_FEE_BASIS_POINTS))).div(bigNumberify(BASIS_POINTS_DIVISOR));
            position.closingFee = position.size.sub(afterFeeUsd)
            position.positionFee = position.size.sub(afterFeeUsd)
        }

        position.totalFees = position.positionFee.add(position.fundingFee);
        position.pendingDelta = position.delta;
        if (position.collateral.gt(0)) {
            position.hasLowCollateral =
                position.collateralAfterFee.lt(0) || position.size.div(position.collateralAfterFee.abs()).gt(50);

            if (position.averagePrice && !position.averagePrice.isZero() && position.markPrice) {
                const priceDelta = position.averagePrice.gt(position.markPrice)
                    ? position.averagePrice.sub(position.markPrice)
                    : position.markPrice.sub(position.averagePrice);
                position.pendingDelta = position.size.mul(priceDelta).div(position.averagePrice);

                position.delta = position.pendingDelta;

                if (position.isLong) {
                    position.hasProfit = position.markPrice.gte(position.averagePrice);
                } else {
                    position.hasProfit = position.markPrice.lte(position.averagePrice);
                }
            }

            position.deltaPercentage = position.pendingDelta.mul(10000).div(position.collateral);

            const { deltaStr, deltaPercentageStr } = getDeltaStr({
                delta: position.pendingDelta,
                deltaPercentage: position.deltaPercentage,
                hasProfit: position.hasProfit,
            });

            position.deltaStr = deltaStr;
            position.deltaPercentageStr = deltaPercentageStr;
            position.deltaBeforeFeesStr = deltaStr;

            let hasProfitAfterFees;
            let pendingDeltaAfterFees;

            if (position.hasProfit) {
                if (position.pendingDelta.gt(position.totalFees)) {
                    hasProfitAfterFees = true;
                    pendingDeltaAfterFees = position.pendingDelta.sub(position.totalFees);
                } else {
                    hasProfitAfterFees = false;
                    pendingDeltaAfterFees = position.totalFees.sub(position.pendingDelta);
                }
            } else {
                hasProfitAfterFees = false;
                pendingDeltaAfterFees = position.pendingDelta.add(position.totalFees);
            }

            position.hasProfitAfterFees = hasProfitAfterFees;
            position.pendingDeltaAfterFees = pendingDeltaAfterFees;
            // while calculating delta percentage after fees, we need to add opening fee (which is equal to closing fee) to collateral
            position.deltaPercentageAfterFees = position.pendingDeltaAfterFees
                .mul(10000)
                .div(position.collateral.add(position.closingFee));

            const { deltaStr: deltaAfterFeesStr, deltaPercentageStr: deltaAfterFeesPercentageStr } = getDeltaStr({
                delta: position.pendingDeltaAfterFees,
                deltaPercentage: position.deltaPercentageAfterFees,
                hasProfit: hasProfitAfterFees,
            });

            position.deltaAfterFeesStr = deltaAfterFeesStr;
            position.deltaAfterFeesPercentageStr = deltaAfterFeesPercentageStr;

            if (showPnlAfterFees) {
                position.deltaStr = position.deltaAfterFeesStr;
                position.deltaPercentageStr = position.deltaAfterFeesPercentageStr;
            }

            let netValue = position.hasProfit
                ? position.collateral.add(position.pendingDelta)
                : position.collateral.sub(position.pendingDelta);

            netValue = netValue.sub(position.fundingFee).sub(position.closingFee);
            position.netValue = netValue;
        }

        position.collateralAmountAfterFee = position.collateralAfterFee.mul(
            expandDecimals(1, collateralToken && collateralToken.decimals ? collateralToken.decimals : 1)).div(
            position.isLong && collateralToken && collateralToken.minPrice ?
            collateralToken.minPrice :
            collateralToken && collateralToken.maxPrice ?
            collateralToken.maxPrice : 
            // bigNumberify(1)
            ethers.utils.parseUnits('1', 30)
        )
        
        const totalFee = positionFeeData && positionFeeData.length && position && position.indexToken && position.indexToken.address ?  positionFeeData.filter(item => item.index_token == position.indexToken.address && item.is_long == position.isLong)[0] : {}
        const tf = totalFee && totalFee.total_fees  ? ethers.utils.parseUnits(totalFee.total_fees, 0) : bigNumberify(0)
        position.totalFee = tf
        position.leverage = getLeverage({
            size: position.size,
            collateral: position.collateral,
            fundingFee: position.fundingFee,
            hasProfit: position.hasProfit,
            delta: position.delta,
            includeDelta,
            totalFee: tf,
            symbol:position.indexToken?.symbol,
            isLong:position.isLong
        });

        position.leverageStr = getLeverageStr(position.leverage);

        positionsMap[key] = position;

        _applyPendingChanges(position, pendingPositions);
        if (positionTotalSizeDeltaData && positionTotalSizeDeltaData.length) {
            for (let j = 0; j < positionTotalSizeDeltaData.length; j++) {
                if (position && position.indexToken && position.indexToken.address) {
                    if (position.isLong == positionTotalSizeDeltaData[j].is_long && position.indexToken.address == positionTotalSizeDeltaData[j].index_token) {
                        position.usedSize = bigNumberify(positionTotalSizeDeltaData[j].total_size_delta || 0)
                        position.availableSize = position.size.sub(position.usedSize).lt(0) ? bigNumberify(0) : position.size.sub(position.usedSize)
                    }
                }
            }
        }

        if ((position.size && position.size.gt(0)) || position.hasPendingChanges) {
            if (positionSortData && positionSortData.length) {
                for (let i = 0; i < positionSortData.length; i ++) {
                    if (position && position.indexToken && position.indexToken.address) {
                        if (position.isLong == positionSortData[i].is_long && position.indexToken.address == positionSortData[i].index_token) {
                            if (
                                firstPosition &&
                                firstPosition.indexTokenAddress == positionSortData[i].index_token &&
                                firstPosition.isLong == positionSortData[i].is_long) {
                                positions[0] = position
                            } else {
                                positions[positionSortData[i].id] = position
                            }
                        }
                    }
                }
            } else {
                if (position && position.indexToken && firstPosition && firstPosition.isLong == position.isLong && firstPosition.indexTokenAddress == position.indexToken.address) {
                    positions.unshift(position);
                } else {
                    positions.push(position);
                }
            }
            if (position && position.indexToken && position.indexToken.address) {
                if (!positionFilterList.filter(item => item.value == position.indexToken.symbol).length) {
                    positionFilterList.push({
                        value: position.indexToken.symbol,
                        label: position.indexToken.symbol,
                    })
                }
            }
        }
    }
    return { positions: positions.filter(item => item), positionsMap, positionFilterList };
   
}
function _applyPendingChanges(position, pendingPositions) {
    if (!pendingPositions) {
        return;
    }
    const { key } = position;

    if (
        pendingPositions[key] &&
        pendingPositions[key].updatedAt &&
        pendingPositions[key].pendingChanges &&
        pendingPositions[key].updatedAt + (600 * 1000) > Date.now()
    ) {
        const { pendingChanges } = pendingPositions[key];
        if (pendingChanges.size && position.size.eq(pendingChanges.size)) {
            return;
        }

        if (pendingChanges.expectingCollateralChange && !position.collateral.eq(pendingChanges.collateralSnapshot)) {
            return;
        }

        position.hasPendingChanges = true;
        position.pendingChanges = pendingChanges;
        if (pendingChanges.size) {
            position.size = pendingChanges.size
        }
    } else {
        position.hasPendingChanges = false;
    }
}

export function getSizeCollateralQuantity(size, averagePrice) {

    // const s = formatAmount(size, USD_DECIMALS, USDG_DECIMALS)
    // if (!Number(s)) {
    //     return 0
    // }
    // const a = formatAmount(averagePrice, USD_DECIMALS, USDG_DECIMALS)
    // if (!Number(a)) {
    //     return 0
    // }

    // return s / a

    if (!size || !averagePrice || size.eq(0) || averagePrice.eq(0)) {
        return 0
    }
    const ps = ethers.utils.formatUnits(size, USD_DECIMALS, USD_DECIMALS)
    const pa = ethers.utils.formatUnits(averagePrice, USD_DECIMALS, USD_DECIMALS)
    const bigPs = new BigNumberJS(ps)
    const bigPa = new BigNumberJS(pa)
    // const h = size.div(averagePrice)
    return bigPs.div(bigPa).toString()
}

export function getMarginCollateralQuantity(sizeDelta, size, collateralAmountAfterFee) {
    if (!size || !sizeDelta || !collateralAmountAfterFee || size.eq(0) || sizeDelta.eq(0) || collateralAmountAfterFee.eq(0)) {
        return 0
    }
    const ps = ethers.utils.formatUnits(size, USD_DECIMALS, USD_DECIMALS)
    const pd = ethers.utils.formatUnits(sizeDelta, USD_DECIMALS, USD_DECIMALS)
    const pc = ethers.utils.formatUnits(collateralAmountAfterFee, USDG_DECIMALS, USDG_DECIMALS)
    const psBN = new BigNumberJS(ps)
    const pdBN = new BigNumberJS(pd)
    const pcBN = new BigNumberJS(pc)
    return pdBN.div(psBN).multipliedBy(pcBN).toString()
}

export function getLiquidationPrice({ size, collateral, averagePrice, isLong, fundingFee }) {
    if (!size || !collateral || !averagePrice) {
        return;
    }
    const totalFees = _calculateTotalFees(size, fundingFee || BigNumber.from("0"));
    const liquidationPriceForFees = _getLiquidationPriceFromDelta({
        liquidationAmount: totalFees,
        size,
        collateral,
        averagePrice,
        isLong,
    });

    const liquidationPriceForMaxLeverage = _getLiquidationPriceFromDelta({
        liquidationAmount: size.mul(BASIS_POINTS_DIVISOR).div(MAX_LEVERAGE),
        size: size,
        collateral,
        averagePrice,
        isLong,
    });
    //28274731091414795801288558631240194
    //28842902920425109170021947005592622

    if (!liquidationPriceForFees) {
        return liquidationPriceForMaxLeverage;
    }

    if (!liquidationPriceForMaxLeverage) {
        return liquidationPriceForFees;
    }

    if (isLong) {
        // return the higher price
        return liquidationPriceForFees.gt(liquidationPriceForMaxLeverage)
            ? liquidationPriceForFees
            : liquidationPriceForMaxLeverage;
    }

    // return the lower price
    return liquidationPriceForFees.lt(liquidationPriceForMaxLeverage)
        ? liquidationPriceForFees
        : liquidationPriceForMaxLeverage;
}

function _calculateTotalFees(size, fundingFees) {
    return size.mul(MARGIN_FEE_BASIS_POINTS).div(BASIS_POINTS_DIVISOR).add(LIQUIDATION_FEE);
}
export function _getLiquidationPriceFromDelta({
    liquidationAmount,
    size,
    collateral,
    averagePrice,
    isLong,
}) {
    if (!size || size.eq(0)) {
        return;
    }

    if (liquidationAmount.gt(collateral)) {
        const liquidationDelta = liquidationAmount.sub(collateral);
        const priceDelta = liquidationDelta.mul(averagePrice).div(size);

        return isLong ? averagePrice.add(priceDelta) : averagePrice.sub(priceDelta);
    }

    const liquidationDelta = collateral.sub(liquidationAmount);
    const priceDelta = liquidationDelta.mul(averagePrice).div(size);

    return isLong ? averagePrice.sub(priceDelta) : averagePrice.add(priceDelta);
}


//======== positions

export function isHashZero(value) {
    return value === ethers.constants.HashZero;
}

export function isAddressZero(value) {
    return value === ethers.constants.AddressZero;
}

// ============= other

export function isMobileDevice(navigator) {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}


export function shortenAddress(address, length) {
    if (!length) {
        return "";
    }
    if (!address) {
        return address;
    }
    if (address.length < 10) {
        return address;
    }
    let left = Math.floor((length - 3) / 2) + 1;
    return address.substring(0, left) + "..." + address.substring(address.length - (length - (left + 3)), address.length);
}


export function isTriggerRatioInverted(fromTokenInfo, toTokenInfo) {
    if (!toTokenInfo || !isEmpty(toTokenInfo) || !fromTokenInfo || !isEmpty(fromTokenInfo)) return false;
    if (toTokenInfo.isStable || toTokenInfo.isUsdg) return true;
    if (toTokenInfo.maxPrice) return toTokenInfo.maxPrice.lt(fromTokenInfo.maxPrice);
    return false;
}

export function getExchangeRate(tokenAInfo, tokenBInfo, inverted) {
    if (!tokenAInfo || !tokenAInfo.minPrice || !tokenBInfo || !tokenBInfo.maxPrice) {
        return;
    }
    if (inverted) {
        return tokenAInfo.minPrice.mul(PRECISION).div(tokenBInfo.maxPrice);
    }
    return tokenBInfo.maxPrice.mul(PRECISION).div(tokenAInfo.minPrice);
}

export function getTwitterIntentURL(text, url = "", hashtag = "") {
    let finalURL = "https://twitter.com/intent/tweet?text=";
    if (text.length > 0) {
        finalURL += Array.isArray(text) ? text.map((t) => encodeURIComponent(t)).join("%0a%0a") : encodeURIComponent(text);
        if (hashtag.length > 0) {
            finalURL += "&hashtags=" + encodeURIComponent(hashtag.replace(/#/g, ""));
        }
        if (url.length > 0) {
            finalURL += "&url=" + encodeURIComponent(url);
        }
    }
    return finalURL;
}

// ============= other




export function calculatePositionDelta(
    price,
    { size, collateral, isLong, averagePrice, lastIncreasedTime },
    sizeDelta
) {
    if (!sizeDelta) {
        sizeDelta = size;
    }
    const priceDelta = averagePrice.gt(price) ? averagePrice.sub(price) : price.sub(averagePrice);
    let delta = averagePrice.gt(bigNumberify(0)) ? sizeDelta.mul(priceDelta).div(averagePrice) : bigNumberify(0);
    const pendingDelta = delta;

    const minProfitExpired = lastIncreasedTime + MIN_PROFIT_TIME < Date.now() / 1000;
    const hasProfit = isLong ? price.gt(averagePrice) : price.lt(averagePrice);
    if (!minProfitExpired && hasProfit && delta.mul(BASIS_POINTS_DIVISOR).lte(size.mul(MIN_PROFIT_BIPS))) {
        delta = bigNumberify(0);
    }

    const deltaPercentage = collateral.gt(bigNumberify(0)) ? delta.mul(BASIS_POINTS_DIVISOR).div(collateral) : bigNumberify(0)
    const pendingDeltaPercentage = collateral.gt(bigNumberify(0)) ? pendingDelta.mul(BASIS_POINTS_DIVISOR).div(collateral) : bigNumberify(0)

    return {
        delta,
        pendingDelta,
        pendingDeltaPercentage,
        hasProfit,
        deltaPercentage,
    };
}

export function useHasOutdatedUi() {
    return { data: false };
    const url = getServerUrl("ARBITRUM", "/ui_version");
    const { data, mutate } = useSWR([url], {
        // @ts-ignore
        fetcher: (...args) => fetch(...args).then((res) => res.text()),
    });

    let hasOutdatedUi = false;

    if (data && parseFloat(data) > parseFloat('0')) {
        hasOutdatedUi = true;
    }

    return { data: hasOutdatedUi, mutate };
}


export async function createSwapOrder(
    chainId,
    library,
    path,
    amountIn,
    minOut,
    triggerRatio,
    nativeTokenAddress,
    opts
) {
    const executionFee = getConstant(chainId, "SWAP_ORDER_EXECUTION_GAS_FEE");
    const triggerAboveThreshold = false;
    let shouldWrap = false;
    let shouldUnwrap = false;
    opts.value = executionFee;

    if (path[0] === AddressZero) {
        shouldWrap = true;
        opts.value = opts.value.add(amountIn);
    }
    if (path[path.length - 1] === AddressZero) {
        shouldUnwrap = true;
    }
    path = replaceNativeTokenAddress(path, nativeTokenAddress);

    const params = [path, amountIn, minOut, triggerRatio, triggerAboveThreshold, executionFee, shouldWrap, shouldUnwrap];

    const orderBookAddress = getContract(chainId, "OrderBook");
    const contract = new ethers.Contract(orderBookAddress, OrderBookABI, library.getSigner());

    return callContract(chainId, contract, "createSwapOrder", params, opts, null, orderBookAddress, OrderBookABI);
}

function invariant(condition, errorMsg) {
    if (!condition) {
        throw new Error(errorMsg);
    }
}

export async function createIncreaseOrder(
    chainId,
    library,
    nativeTokenAddress,
    path,
    amountIn,
    indexTokenAddress,
    minOut,
    sizeDelta,
    collateralTokenAddress,
    isLong,
    triggerPrice,
    leverageMultiplier,
    opts,
    fund
) {
    // invariant(!isLong || indexTokenAddress === collateralTokenAddress, "invalid token addresses");
    invariant(indexTokenAddress !== AddressZero, "indexToken is 0");
    invariant(collateralTokenAddress !== AddressZero, "collateralToken is 0");

    const fromETH = path[0] === AddressZero;

    path = replaceNativeTokenAddress(path, nativeTokenAddress);
    const shouldWrap = fromETH;
    const triggerAboveThreshold = !isLong;
    let params = []
    params = [
        path,
        amountIn,
        indexTokenAddress,
        sizeDelta,
        collateralTokenAddress,
        isLong,
        triggerPrice,
        triggerAboveThreshold,
        leverageMultiplier,
    ];

    const orderBookAddress = getContract(chainId, "OrderBook");
    let contract = ''
    contract = new ethers.Contract(orderBookAddress, OrderBookABI, library.getSigner());

    // const contract = new ethers.Contract(orderBookAddress, OrderBookABI, library.getSigner());
    return callContract(chainId, contract, "createIncreaseOrder", params, opts, null, orderBookAddress, OrderBookABI);
}

export function shouldInvertTriggerRatio(tokenA, tokenB) {
    if (tokenB.isStable || tokenB.isUsdg) return true;
    if (tokenB.maxPrice && tokenA.maxPrice && tokenB.maxPrice.lt(tokenA.maxPrice)) return true;
    return false;
}

export function getExchangeRateDisplay(rate, tokenA, tokenB, opts = {}) {
    if (!rate || !tokenA || !tokenB) return "...";
    if (shouldInvertTriggerRatio(tokenA, tokenB)) {
        [tokenA, tokenB] = [tokenB, tokenA];
        rate = PRECISION.mul(PRECISION).div(rate);
    }
    const rateValue = formatAmount(rate, USD_DECIMALS, tokenA.isStable || tokenA.isUsdg ? 2 : 4, true);
    if (opts.omitSymbols) {
        return rateValue;
    }
    return `${rateValue} ${tokenA.symbol} / ${tokenB.symbol}`;
}

export function getExchangeLabelDisplay(tokenA, tokenB) {
    if (!tokenA || !tokenB) return "...";
    if (shouldInvertTriggerRatio(tokenA, tokenB)) {
        [tokenA, tokenB] = [tokenB, tokenA];
    }
    return `${tokenA.symbol} / ${tokenB.symbol}`;
}

export function useExecutionFee(library, active, chainId, infoTokens) {
    const positionRouterAddress = getContract(chainId, "PositionRouter");
    const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
    const { data: minExecutionFee } = useSWR([active, chainId, positionRouterAddress, "minExecutionFee"], {
        fetcher: contractFetcher(library, PositionRouterABI),
    });
    const { data: gasPrice } = useSWR(["gasPrice", chainId], {
        fetcher: () => {
            return new Promise(async (resolve, reject) => {
                const provider = getProvider(library, chainId);
                if (!provider) {
                    resolve(undefined);
                    return;
                }

                try {
                    const gasPrice = await provider.getGasPrice();
                    resolve(gasPrice);
                } catch (e) {
                    // eslint-disable-next-line no-console
                    console.error(e);
                }
            });
        },
    });

    let multiplier = 1000

    // if (chainId === ARBITRUM || chainId === ARBITRUM_TESTNET) {
    //     multiplier = 2150000;
    // }

    // multiplier for Avalanche is just the average gas usage
    // if (chainId === AVALANCHE) {
    //     multiplier = 700000;
    // }

    let finalExecutionFee = minExecutionFee;

    // if (gasPrice && minExecutionFee) {
    //     const estimatedExecutionFee = gasPrice.mul(multiplier);
    //     if (estimatedExecutionFee.gt(minExecutionFee)) {
    //         finalExecutionFee = estimatedExecutionFee;
    //     }
    // }

    const finalExecutionFeeUSD = getUsd(finalExecutionFee, nativeTokenAddress, false, infoTokens);
    const isFeeHigh = finalExecutionFeeUSD?.gt(expandDecimals(getHighExecutionFee(chainId), USD_DECIMALS));
    const errorMessage =
        isFeeHigh &&
        `The network cost to send transactions is high at the moment, please check the "Execution Fee" value before proceeding.`;

    return {
        minExecutionFee: finalExecutionFee,
        minExecutionFeeUSD: finalExecutionFeeUSD,
        minExecutionFeeErrorMessage: errorMessage,
    };
}


export function getOrderError(account, order, positionsMap, position) {
    if (order.type !== DECREASE) {
        return;
    }

    const positionForOrder = position ? position : getPositionForOrder(account, order, positionsMap);

    if (!positionForOrder) {
        return `No open position, order cannot be executed unless a position is opened`;
    }
    if (positionForOrder.size.lt(order.sizeDelta)) {
        return `Order size is bigger than position, will only be executable if position increases`;
    }

    if (positionForOrder.size.gt(order.sizeDelta)) {
        if (positionForOrder.size.sub(order.sizeDelta).lt(positionForOrder.collateral.sub(order.collateralDelta))) {
            return `Order cannot be executed as it would reduce the position's leverage below 1`;
        }
        if (positionForOrder.size.sub(order.sizeDelta).lt(expandDecimals(5, USD_DECIMALS))) {
            return `Order cannot be executed as the remaining position would be smaller than $5.00`;
        }
    }
}



export function getPositionForOrder(account, order, positionsMap) {
    const key = getPositionKey(account, order.collateralToken, order.indexToken, order.isLong);

    const position = positionsMap[key];

    return position && position.size && position.size.gt(0) ? position : null;
}

export async function cancelSwapOrder(chainId, library, index, opts) {
    const params = [index];
    const method = "cancelSwapOrder";
    const orderBookAddress = getContract(chainId, "OrderBook");
    const contract = new ethers.Contract(orderBookAddress, OrderBookABI, library.getSigner());

    return callContract(chainId, contract, method, params, opts, null, orderBookAddress, OrderBookABI);
}

export async function cancelDecreaseOrder(chainId, library, index, opts) {
    const params = [index];
    const method = "cancelDecreaseOrder";
    const orderBookAddress = getContract(chainId, "OrderBook");
    const contract = new ethers.Contract(orderBookAddress, OrderBookABI, library.getSigner());

    return callContract(chainId, contract, method, params, opts, null, orderBookAddress, OrderBookABI);
}

export async function cancelIncreaseOrder(chainId, library, index, opts) {
    const params = [index];
    const method = "cancelIncreaseOrder";
    const orderBookAddress = getContract(chainId, "OrderBook");
    const contract = new ethers.Contract(orderBookAddress, OrderBookABI, library.getSigner());

    return callContract(chainId, contract, method, params, opts,null, orderBookAddress, OrderBookABI);
}

export function handleCancelOrder(chainId, library, order, opts) {
    let func;
    if (order.type === SWAP) {
        func = cancelSwapOrder;
    } else if (order.type === INCREASE) {
        func = cancelIncreaseOrder;
    } else if (order.type === DECREASE) {
        func = cancelDecreaseOrder;
    }

    return func(chainId, library, order.index, {
        pendingMsg: `${i18next.t("Pending")} ${i18next.t("Cancel")}...`,
        successMsg: `${i18next.t("Order cancelled")}.`,
        failMsg: `${i18next.t("Cancel")} ${i18next.t("failed")}.`,
        sentMsg: `${i18next.t("Cancel")} ${i18next.t("submitted")}.`,
        pendingTxns: opts.pendingTxns,
        setPendingTxns: opts.setPendingTxns,
        gasless: opts.gasless
    });
}


export async function createDecreaseOrder(
    chainId,
    library,
    indexTokenAddress,
    sizeDelta,
    collateralTokenAddress,
    collateralDelta,
    isLong,
    triggerPrice,
    triggerAboveThreshold,
    leverage,
    opts
) {
    // invariant(!isLong || indexTokenAddress === collateralTokenAddress, "invalid token addresses");
    invariant(indexTokenAddress !== AddressZero, "indexToken is 0");
    invariant(collateralTokenAddress !== AddressZero, "collateralToken is 0");

    const executionFee = getConstant(chainId, "DECREASE_ORDER_EXECUTION_GAS_FEE");

    const params = [
        indexTokenAddress,
        sizeDelta,
        collateralTokenAddress,
        collateralDelta,
        isLong,
        triggerPrice,
        triggerAboveThreshold,
        leverage,
    ];
    // opts.value = executionFee;
    const orderBookAddress = getContract(chainId, "OrderBook");
    const contract = new ethers.Contract(orderBookAddress, OrderBookABI, library.getSigner());

    return callContract(chainId, contract, "createDecreaseOrder", params, opts, null, orderBookAddress, OrderBookABI);
}
export async function batchCreateDecreaseOrder(
    chainId,
    library,
    params,
    opts
) {
    // invariant(!isLong || indexTokenAddress === collateralTokenAddress, "invalid token addresses");
    if (params && params.length) {
        for (let i = 0; i < params.length; i ++) {
            invariant(params[i][0] !== AddressZero, "indexToken is 0");
            invariant(params[i][2] !== AddressZero, "collateralToken is 0");
        }
    }
    // invariant(indexTokenAddress !== AddressZero, "indexToken is 0");
    // invariant(collateralTokenAddress !== AddressZero, "collateralToken is 0");

    const executionFee = getConstant(chainId, "DECREASE_ORDER_EXECUTION_GAS_FEE");

    // const params = params 
    // [
    //     indexTokenAddress,
    //     sizeDelta,
    //     collateralTokenAddress,
    //     collateralDelta,
    //     isLong,
    //     triggerPrice,
    //     triggerAboveThreshold,
    //     leverage,
    // ];
    // opts.value = executionFee;
    const orderBookAddress = getContract(chainId, "OrderBook");
    const contract = new ethers.Contract(orderBookAddress, OrderBookABI, library.getSigner());

    return callContract(chainId, contract, "batchCreateDecreaseOrder", [params], opts, null, orderBookAddress, OrderBookABI);
}

export function getProfitPrice(closePrice, position) {
    let profitPrice;
    if (position && position.averagePrice && closePrice) {
        profitPrice = position.isLong
            ? position.averagePrice.mul(BASIS_POINTS_DIVISOR + MIN_PROFIT_BIPS).div(BASIS_POINTS_DIVISOR)
            : position.averagePrice.mul(BASIS_POINTS_DIVISOR - MIN_PROFIT_BIPS).div(BASIS_POINTS_DIVISOR);
    }
    return profitPrice;
}



export function getTokenAmountFromUsd(
    infoTokens,
    tokenAddress,
    usdAmount,
    opts = {}
) {
    if (!usdAmount) {
        return;
    }

    if (tokenAddress === USDG_ADDRESS) {
        return usdAmount.mul(expandDecimals(1, 18)).div(PRECISION);
    }

    const info = getTokenInfo(infoTokens, tokenAddress);

    if (!info) {
        return;
    }

    const price = opts.overridePrice || (opts.max ? info.maxPrice : info.minPrice);

    if (!BigNumber.isBigNumber(price) || price.lte(0)) {
        return;
    }

    return usdAmount.mul(expandDecimals(1, info.decimals)).div(price);
}

export async function cancelMultipleOrders(chainId, library, allIndexes = [], opts) {
    const ordersWithTypes = groupBy(allIndexes, (v) => v.split("-")[0]);
    function getIndexes(key) {
        if (!ordersWithTypes[key]) return;
        return ordersWithTypes[key].map((d) => d.split("-")[1]);
    }
    // params order => swap, increase, decrease
    const params = ["Swap", "Increase", "Decrease"].map((key) => getIndexes(key) || []);
    const method = "cancelMultiple";
    const orderBookAddress = getContract(chainId, "OrderBook");
    const contract = new ethers.Contract(orderBookAddress, OrderBookABI, library.getSigner());
    return callContract(chainId, contract, method, params, opts,null, orderBookAddress, OrderBookABI);
}

export async function getAccountControl(chainId, subAccount) {
    const deriwSubAccountPublicAddress = getContract(chainId, "DeriwSubAccountPublic");
    const provider = getProvider(undefined, chainId);
    const contract = new ethers.Contract(
        deriwSubAccountPublicAddress, 
        DeriwSubAccountPublicABI, 
        provider
    );
    
    try {
        const result = await contract.readAccountControl(subAccount);
        return result;
    } catch (error) {
        console.error("getAccountControl error:", error);
        return null;
    }
}
