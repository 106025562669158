export const SET_SUB_ACCOUNT = "SET_SUB_ACCOUNT"
export const SET_CONNECTION_MODAL = "SET_CONNECTION_MODAL"

export const setSubAccount = (data) => ({
    type: SET_SUB_ACCOUNT,
    payload: data
})

export const setConnectionModal = (data) => ({
    type: SET_CONNECTION_MODAL,
    payload: data
})

